export default (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 345.716 345.716">
    <defs>
        <clipPath id="clipPath">
        <rect id="Rectangle_564" dataname="Rectangle 564" width="227.745" height="228.053" fill="#fdf6da"/>
        </clipPath>
        <clipPath id="clipPath-2">
        <path id="Path_1004" dataname="Path 1004" d="M-.08,80.9l141.615-.08.047-80.953L-.033-.056Z" transform="translate(0 0)" fill="#fdf6da"/>
        </clipPath>
    </defs>
    <g id="showAll-outer">
  <g id="Group_1002" dataname="Group 1002" transform="translate(-8984.085 2703.122)">
    <g id="Group_942" dataname="Group 942" transform="translate(8918.07 -2598.759) rotate(-29)">
      <g id="Group_944" dataname="Group 944" transform="translate(0 0)">
        <path id="Path_912" dataname="Path 912" d="M102.193,2.5A127.131,127.131,0,1,1,2.5,152.118,127.13,127.13,0,0,1,102.193,2.5" transform="translate(48.561 48.56)" fill="#d90431"/>
        <circle id="Ellipse_255" dataname="Ellipse 255" cx="127.131" cy="127.131" r="127.131" transform="translate(0 213.777) rotate(-57.222)" fill="none" stroke="#d90431" strokeMiterlimit="10" strokeWidth="1"/>
      </g>
    </g>
    <g id="Group_955" dataname="Group 955" transform="translate(9064.831 -2622.375)">
      <g id="Group_964" dataname="Group 964" transform="translate(0 0)">
        <path id="Path_914" dataname="Path 914" d="M90.9,1.5A89.4,89.4,0,1,1,1.5,90.9,89.4,89.4,0,0,1,90.9,1.5Z" transform="translate(1.209 1.209)" fill="none" stroke="#f5ead1" strokeMiterlimit="10" strokeWidth="3"/>
      </g>
    </g>
    <path id="Path_955" dataname="Path 955" d="M886.8,248.645h-3.657l2.583-2.583a2.885,2.885,0,0,0-4.08-4.08l-2.577,2.577V241a2.885,2.885,0,0,0-5.77,0v3.57l-2.527-2.527a2.885,2.885,0,0,0-4.08,0h0a2.885,2.885,0,0,0,0,4.08l2.524,2.524h-3.573a2.885,2.885,0,1,0,0,5.77h3.57l-2.527,2.527a2.885,2.885,0,0,0,4.08,4.08l2.533-2.533v3.666a2.885,2.885,0,1,0,5.77,0V258.5l2.583,2.583a2.885,2.885,0,0,0,4.08-4.08l-2.586-2.586H886.8a2.885,2.885,0,1,0,0-5.77" transform="translate(8361.125 -2711.165)" fill="#fdf6da"/>
    <g id="Group_966" dataname="Group 966" transform="translate(9001.312 -2572.077) rotate(-30)">
      <g id="Group_965" dataname="Group 965" clipPath="url(#clipPath)">
        <path id="Path_956" dataname="Path 956" d="M150.967,8.148a1.028,1.028,0,0,1-.1-.928,1.049,1.049,0,0,1,.76-.526l1.418-.35a1.138,1.138,0,0,1,1.439.683l3.557,7.553q.143.325.25.585t.176.454c.049.143.09.281.123.414l.04-.01a2.468,2.468,0,0,1-.063-.429c-.006-.139-.022-.3-.046-.486a3.708,3.708,0,0,1-.021-.641l-.007-8.449a1.178,1.178,0,0,1,.982-1.259l.859-.212a1.149,1.149,0,0,1,1.434.663l3.881,7.494c.109.213.2.406.269.579s.136.323.2.449a2.435,2.435,0,0,1,.143.41l.04-.01c-.032-.133-.06-.275-.083-.425s-.041-.3-.066-.481a5.509,5.509,0,0,1-.04-.636l-.227-8.4a1.144,1.144,0,0,1,.981-1.259l1.358-.335a1.051,1.051,0,0,1,.918.113,1.033,1.033,0,0,1,.341.869l-.087,12.878A1.173,1.173,0,0,1,168.4,17.7l-1.937.478a1.178,1.178,0,0,1-1.454-.658l-3.1-5.866a4.237,4.237,0,0,1-.3-.614q-.116-.3-.21-.509-.1-.25-.183-.485l-.04.01q.039.245.063.514.049.454.071,1.232l-.016,6.634a1.178,1.178,0,0,1-.981,1.259l-1.977.487a1.178,1.178,0,0,1-1.454-.658Z" transform="translate(-73.268 -1.24)" fill="#fdf6da"/>
        <path id="Path_957" dataname="Path 957" d="M194.533,1.908A1,1,0,0,1,195.585.7l1.334-.091a1,1,0,0,1,1.2,1.052l.323,4.74,5.335-.363-.322-4.74A1,1,0,0,1,204.51.1l1.334-.09a1,1,0,0,1,1.206,1.051l.844,12.413a1,1,0,0,1-1.052,1.206l-1.333.091a1,1,0,0,1-1.205-1.052L203.989,9.1l-5.334.363.314,4.616a1,1,0,0,1-1.052,1.206l-1.334.09a1,1,0,0,1-1.205-1.051Z" transform="translate(-94.518 0)" fill="#fdf6da"/>
        <path id="Path_958" dataname="Path 958" d="M228.673,1.494a1.234,1.234,0,0,1,1.341-.827l1.845.14a1.235,1.235,0,0,1,1.2,1.019l3.3,12.769a.99.99,0,0,1-.122.939,1.016,1.016,0,0,1-.9.283l-1.354-.1a1.238,1.238,0,0,1-1.2-1.04l-.454-2.179-4.573-.346-.776,2.085a1.189,1.189,0,0,1-1.323.849l-1.353-.1a1.015,1.015,0,0,1-.846-.415.99.99,0,0,1,.021-.946Zm3.039,8.067-.6-2.912q-.076-.356-.136-.794t-.132-.835c-.046-.306-.091-.618-.135-.938l-.041,0c-.093.309-.184.612-.275.907q-.133.382-.257.806t-.253.764L228.82,9.343Z" transform="translate(-108.524 -0.322)" fill="#fdf6da"/>
        <path id="Path_959" dataname="Path 959" d="M255.473,6.041,252.206,5.4a1,1,0,0,1-.892-1.328l.159-.807a1,1,0,0,1,1.328-.891L262.87,4.354a1,1,0,0,1,.891,1.328l-.158.808a1,1,0,0,1-1.329.891l-3.269-.644-2.027,10.291a1,1,0,0,1-1.328.891l-1.311-.259a1,1,0,0,1-.891-1.328Z" transform="translate(-122.092 -1.134)" fill="#fdf6da"/>
        <path id="Path_960" dataname="Path 960" d="M277.286,12.572a1.175,1.175,0,0,1,.162-.9L278.9,8.97a1.137,1.137,0,0,1,1.465-.625l.691.2a1.011,1.011,0,0,1,.753.581,1.015,1.015,0,0,1-.223.92l-1.853,2.633a1.267,1.267,0,0,1-1.5.509l-.257-.075a.9.9,0,0,1-.689-.541" transform="translate(-134.712 -4.021)" fill="#fdf6da"/>
        <path id="Path_961" dataname="Path 961" d="M281.826,25a1.571,1.571,0,0,1-.17-.361.93.93,0,0,1-.04-.39.883.883,0,0,1,.15-.394,1.491,1.491,0,0,1,.423-.388l.924-.64a1.031,1.031,0,0,1,.813-.23,1.465,1.465,0,0,1,.742.536l.326.388c.126.151.277.313.451.49a4.413,4.413,0,0,0,.61.507,3.636,3.636,0,0,0,.791.41,1.908,1.908,0,0,0,1.211.14.9.9,0,0,0,.615-.547,1.064,1.064,0,0,0-.141-.989,6.3,6.3,0,0,0-.884-1.062q-.56-.553-1.221-1.189a7.656,7.656,0,0,1-1.143-1.391,4.75,4.75,0,0,1-.662-1.649,3.56,3.56,0,0,1,.231-1.968,4.2,4.2,0,0,1,1.028-1.579,4.332,4.332,0,0,1,1.536-.945,4.895,4.895,0,0,1,1.882-.264,6.565,6.565,0,0,1,2.055.439,7.367,7.367,0,0,1,2.083,1.175,6.666,6.666,0,0,1,1.241,1.252,1.136,1.136,0,0,1,.228.846,1.364,1.364,0,0,1-.449.72l-.8.729q-.5.447-.879.315a1.7,1.7,0,0,1-.7-.5q-.393-.413-.826-.808A3.427,3.427,0,0,0,290.142,17a1.994,1.994,0,0,0-1.26-.159.9.9,0,0,0-.605.552,1.051,1.051,0,0,0,.137,1,6.268,6.268,0,0,0,.891,1.043q.564.543,1.231,1.16a7.21,7.21,0,0,1,1.155,1.362,4.623,4.623,0,0,1,.665,1.637,3.556,3.556,0,0,1-.231,1.97,4.608,4.608,0,0,1-.941,1.523,4.168,4.168,0,0,1-1.451,1.011,4.815,4.815,0,0,1-1.873.356,6.107,6.107,0,0,1-2.188-.436,7.468,7.468,0,0,1-1.342-.663,9.536,9.536,0,0,1-1.1-.8,8,8,0,0,1-.851-.829,5.26,5.26,0,0,1-.55-.725" transform="translate(-136.83 -6.549)" fill="#fdf6da"/>
        <path id="Path_962" dataname="Path 962" d="M318.393,27.43a1,1,0,0,1,1.537-.443l1.171.646a1,1,0,0,1,.443,1.537l-6.013,10.892a1,1,0,0,1-1.537.444l-1.17-.646a1,1,0,0,1-.444-1.537Z" transform="translate(-151.686 -13.018)" fill="#fdf6da"/>
        <path id="Path_963" dataname="Path 963" d="M330.787,35.469a1.062,1.062,0,0,1,1.588-.2l1.5,1.082a1.235,1.235,0,0,1,.495,1.5l-1.6,5.359q-.1.356-.242.776c-.092.281-.183.54-.27.781q-.152.423-.3.845l.033.024.7-.557q.3-.241.655-.5c.236-.176.455-.334.659-.476l4.573-3.22a1.235,1.235,0,0,1,1.576-.006l1.5,1.081a1.062,1.062,0,0,1,.315,1.57L335.519,54.2a1.116,1.116,0,0,1-.7.562,1.06,1.06,0,0,1-.855-.236l-1.086-.78a1.062,1.062,0,0,1-.314-1.57l2.931-4.705q.207-.359.457-.723t.471-.637a7.954,7.954,0,0,1,.514-.643l-.033-.024c-.254.189-.5.372-.751.549l-.685.495c-.246.178-.471.338-.673.479l-2.943,2.012a1.266,1.266,0,0,1-1.58-.023l-.934-.672a1.267,1.267,0,0,1-.523-1.492l.974-3.429q.1-.356.239-.791l.252-.807q.135-.435.283-.886l-.034-.024q-.24.334-.464.68-.2.284-.454.648a6.829,6.829,0,0,1-.524.675l-3.532,4.273a1.062,1.062,0,0,1-1.589.2l-1.1-.793a1.063,1.063,0,0,1-.5-.737,1.112,1.112,0,0,1,.313-.839Z" transform="translate(-156.632 -17.006)" fill="#fdf6da"/>
        <path id="Path_964" dataname="Path 964" d="M359.332,57.9a1,1,0,0,1,1.6-.019l3.559,3.479a5.006,5.006,0,0,1,1.139,1.63,4.278,4.278,0,0,1,.348,1.807,4.882,4.882,0,0,1-.423,1.83,5.743,5.743,0,0,1-1.173,1.7,5.816,5.816,0,0,1-1.681,1.219,4.85,4.85,0,0,1-1.827.471,4.4,4.4,0,0,1-1.822-.3,4.889,4.889,0,0,1-1.663-1.094l-1.794-1.754-2.386,2.441a1,1,0,0,1-1.6.019l-.956-.935a1,1,0,0,1-.018-1.6Zm-.316,8a1.714,1.714,0,0,0,1.4.58,2.218,2.218,0,0,0,1.344-.713,2.163,2.163,0,0,0,.675-1.338,1.662,1.662,0,0,0-.589-1.338l-1.309-1.28-2.789,2.853Z" transform="translate(-170.177 -27.934)" fill="#fdf6da"/>
        <path id="Path_965" dataname="Path 965" d="M374.314,82.6A7.761,7.761,0,0,1,377,81.253a7.126,7.126,0,0,1,2.851-.194,7.271,7.271,0,0,1,2.745.973A8.772,8.772,0,0,1,386.381,87a7.275,7.275,0,0,1,.209,2.9,7.111,7.111,0,0,1-.945,2.7,7.76,7.76,0,0,1-2.01,2.23,8.129,8.129,0,0,1-2.742,1.39,7.278,7.278,0,0,1-2.892.225,7.362,7.362,0,0,1-2.777-.948,8.489,8.489,0,0,1-3.788-4.973,7.347,7.347,0,0,1-.176-2.929,7.272,7.272,0,0,1,.985-2.728,8.107,8.107,0,0,1,2.068-2.273m2.255,2.961a4.974,4.974,0,0,0-1.21,1.3,4.3,4.3,0,0,0-.605,1.5,3.812,3.812,0,0,0,.024,1.559,3.911,3.911,0,0,0,1.931,2.536,3.836,3.836,0,0,0,1.5.436,4.3,4.3,0,0,0,1.6-.187,4.974,4.974,0,0,0,1.571-.822,4.6,4.6,0,0,0,1.153-1.252,4.154,4.154,0,0,0,.565-1.465,3.739,3.739,0,0,0-.057-1.534,4.142,4.142,0,0,0-1.932-2.535,3.722,3.722,0,0,0-1.464-.462,4.138,4.138,0,0,0-1.562.156,4.61,4.61,0,0,0-1.514.778" transform="translate(-180.353 -39.354)" fill="#fdf6da"/>
        <path id="Path_966" dataname="Path 966" d="M397.694,106.9a1,1,0,0,1,1.536.447l1.921,3.5a14.525,14.525,0,0,1,.775,1.581,4.708,4.708,0,0,1,.3,1.093,4.027,4.027,0,0,1-.423,2.5,4.788,4.788,0,0,1-2.018,1.942,5.322,5.322,0,0,1-1.1.452,4.849,4.849,0,0,1-1.19.184,4.325,4.325,0,0,1-1.2-.129,3.188,3.188,0,0,1-1.087-.505l-.036.02c-.028.063-.065.138-.111.226a1.875,1.875,0,0,1-.159.287q-.109.165-.238.4l-2.306,3.661q-.356.594-.8.57a.97.97,0,0,1-.764-.6l-.753-1.37a1.45,1.45,0,0,1,.06-1.558l2.591-3.911-.812-1.478-3.353,1.842a1,1,0,0,1-1.535-.447l-.644-1.171a1,1,0,0,1,.446-1.536Zm-2.363,7.188a1.945,1.945,0,0,0,1.064.976,1.739,1.739,0,0,0,1.423-.207,2.383,2.383,0,0,0,.754-.614,1.18,1.18,0,0,0,.2-.9,3.528,3.528,0,0,0-.474-1.288L397.715,111l-3.137,1.723Z" transform="translate(-187.623 -51.848)" fill="#fdf6da"/>
        <path id="Path_967" dataname="Path 967" d="M410.392,134.216l-1.225-3.1a1,1,0,0,1,.636-1.468l.765-.3a1,1,0,0,1,1.467.636l3.774,9.543a1,1,0,0,1-.635,1.468l-.765.3a1,1,0,0,1-1.468-.635l-1.225-3.1-9.753,3.857a1,1,0,0,1-1.468-.636L400,139.542a1,1,0,0,1,.635-1.468Z" transform="translate(-194.297 -62.791)" fill="#fdf6da"/>
        <path id="Path_968" dataname="Path 968" d="M419.059,160.216a1.235,1.235,0,0,1,1.223.994l.477,1.789a1.237,1.237,0,0,1-.567,1.47l-10.968,7.324a.994.994,0,0,1-.927.193,1.013,1.013,0,0,1-.562-.755l-.349-1.312a1.238,1.238,0,0,1,.586-1.476l1.908-1.146-1.179-4.432-2.226-.046a1.187,1.187,0,0,1-1.237-.969l-.349-1.312a1.013,1.013,0,0,1,.113-.934.992.992,0,0,1,.9-.294Zm-6.615,5.528,2.551-1.531q.311-.19.7-.389t.745-.4q.41-.215.842-.436l-.011-.04q-.483.022-.947.039-.4,0-.845.023t-.806.012l-2.979-.08Z" transform="translate(-196.699 -77.405)" fill="#fdf6da"/>
        <path id="Path_969" dataname="Path 969" d="M425.027,183.828a1,1,0,0,1,1.258.989l.19,1.593a1.451,1.451,0,0,1-.594,1.438l-5.636,4.318a8.89,8.89,0,0,1-.744.524l-.771.485q-.425.258-.829.513l0,.041.889-.189q.383-.087.81-.158c.284-.048.542-.085.774-.113l5.7-.681a1,1,0,0,1,1.258.989l.159,1.327a1,1,0,0,1-.99,1.258l-12.353,1.476a1,1,0,0,1-1.257-.989l-.191-1.593a1.433,1.433,0,0,1,.6-1.417l5.612-4.337a9.042,9.042,0,0,1,.732-.543c.275-.185.535-.347.781-.487a9.517,9.517,0,0,1,.831-.493l-.005-.041-.888.189q-.383.086-.81.159t-.773.113l-5.677.679a1,1,0,0,1-1.258-.989l-.158-1.327a1,1,0,0,1,.989-1.257Z" transform="translate(-200.023 -89.312)" fill="#fdf6da"/>
        <path id="Path_970" dataname="Path 970" d="M425.822,218.4l.068-3.331a1,1,0,0,1,1.155-1.107l.822.017a1,1,0,0,1,1.108,1.155l-.212,10.259a1,1,0,0,1-1.154,1.107l-.822-.017a1,1,0,0,1-1.108-1.154l.068-3.33-10.485-.216a1,1,0,0,1-1.108-1.154l.027-1.337a1,1,0,0,1,1.154-1.107Z" transform="translate(-201.23 -103.96)" fill="#fdf6da"/>
        <path id="Path_971" dataname="Path 971" d="M421.978,256.884l.662-3.265a1,1,0,0,1,1.334-.883l.806.163a1,1,0,0,1,.883,1.334l-2.041,10.057a1,1,0,0,1-1.333.883l-.806-.163a1,1,0,0,1-.884-1.333l.662-3.265-10.278-2.085a1,1,0,0,1-.884-1.334l.266-1.31a1,1,0,0,1,1.334-.883Z" transform="translate(-199.242 -122.781)" fill="#fdf6da"/>
        <path id="Path_972" dataname="Path 972" d="M412.018,276.274a7.763,7.763,0,0,1,2.595,1.512,7.122,7.122,0,0,1,1.721,2.281,7.266,7.266,0,0,1,.685,2.83,8.767,8.767,0,0,1-2.092,5.891,7.253,7.253,0,0,1-2.318,1.762,7.115,7.115,0,0,1-2.774.684,7.776,7.776,0,0,1-2.967-.464,8.128,8.128,0,0,1-2.662-1.536,7.272,7.272,0,0,1-1.77-2.3,7.36,7.36,0,0,1-.725-2.844A8.488,8.488,0,0,1,403.8,278.2a7.364,7.364,0,0,1,2.356-1.75,7.272,7.272,0,0,1,2.823-.666,8.117,8.117,0,0,1,3.035.488m-1.246,3.507a5,5,0,0,0-1.746-.3,4.311,4.311,0,0,0-1.584.31,3.819,3.819,0,0,0-1.292.872,3.912,3.912,0,0,0-1.067,3,3.828,3.828,0,0,0,.451,1.492,4.33,4.33,0,0,0,1.033,1.24,4.976,4.976,0,0,0,1.548.866,4.61,4.61,0,0,0,1.679.281,4.148,4.148,0,0,0,1.535-.328,3.722,3.722,0,0,0,1.254-.886,4.15,4.15,0,0,0,1.067-3,3.733,3.733,0,0,0-.414-1.478,4.14,4.14,0,0,0-.984-1.222,4.6,4.6,0,0,0-1.48-.843" transform="translate(-195.179 -133.996)" fill="#fdf6da"/>
        <path id="Path_973" dataname="Path 973" d="M392.687,320.5l8.384.221a.921.921,0,0,1,.9.4.971.971,0,0,1-.13.963l-.831,1.4a1.35,1.35,0,0,1-1.4.7l-2.968-.28c-.28-.039-.554-.074-.822-.105s-.512-.065-.731-.1c-.261-.044-.5-.082-.72-.117l-.021.035q.2.264.446.577.2.263.442.585t.48.68l1.676,2.454a1.349,1.349,0,0,1,.052,1.563l-.83,1.4a.968.968,0,0,1-.784.574q-.448.009-.782-.6l-4.2-7.258-4.208-2.5a1,1,0,0,1-.395-1.55l.683-1.15a1,1,0,0,1,1.55-.394Z" transform="translate(-187.565 -154.408)" fill="#fdf6da"/>
        <path id="Path_974" dataname="Path 974" d="M380.671,337.729A7.755,7.755,0,0,1,382.63,340a7.134,7.134,0,0,1,.882,2.718,7.278,7.278,0,0,1-.276,2.9,8.768,8.768,0,0,1-3.9,4.884,7.254,7.254,0,0,1-2.766.909,7.112,7.112,0,0,1-2.845-.26,7.77,7.77,0,0,1-2.652-1.408,8.118,8.118,0,0,1-2.015-2.321,7.264,7.264,0,0,1-.922-2.75,7.379,7.379,0,0,1,.244-2.925,8.5,8.5,0,0,1,3.9-4.884,7.368,7.368,0,0,1,2.8-.883,7.266,7.266,0,0,1,2.886.292,8.11,8.11,0,0,1,2.709,1.453m-2.323,2.908a4.958,4.958,0,0,0-1.552-.858,4.3,4.3,0,0,0-1.6-.224,3.833,3.833,0,0,0-1.506.4,3.91,3.91,0,0,0-1.989,2.49,3.836,3.836,0,0,0-.06,1.558,4.325,4.325,0,0,0,.571,1.509,4.984,4.984,0,0,0,1.179,1.324,4.626,4.626,0,0,0,1.5.813,4.143,4.143,0,0,0,1.558.191,3.726,3.726,0,0,0,1.474-.428,4.145,4.145,0,0,0,1.99-2.49,3.715,3.715,0,0,0,.092-1.533,4.14,4.14,0,0,0-.531-1.477,4.607,4.607,0,0,0-1.123-1.279" transform="translate(-178.843 -163.231)" fill="#fdf6da"/>
        <path id="Path_975" dataname="Path 975" d="M362.871,367.921a1,1,0,0,1-.072,1.6l-.987.9a1,1,0,0,1-1.6-.071l-5.511-6.024a2.212,2.212,0,0,0-1.7-.84A3.2,3.2,0,0,0,350.257,366a2.212,2.212,0,0,0,.685,1.77l5.511,6.023a1,1,0,0,1-.072,1.6l-.986.9a1,1,0,0,1-1.6-.071l-5.511-6.024a5.74,5.74,0,0,1-1.208-1.975,5.213,5.213,0,0,1-.289-2.16,5.689,5.689,0,0,1,.635-2.169,7.143,7.143,0,0,1,1.567-2,7.286,7.286,0,0,1,2.158-1.4,5.671,5.671,0,0,1,2.225-.446,5.2,5.2,0,0,1,2.126.48,5.738,5.738,0,0,1,1.86,1.378Z" transform="translate(-168.491 -174.936)" fill="#fdf6da"/>
        <path id="Path_976" dataname="Path 976" d="M335.992,377.963a1,1,0,0,1-.268,1.576l-.806.572a1,1,0,0,1-1.577-.269l-.476-.671a1,1,0,0,1,.268-1.577l.805-.571a1,1,0,0,1,1.577.268Zm.644,2.72a1,1,0,0,1,1.577.268l.119.168a2.162,2.162,0,0,1,.415,1.457,9.6,9.6,0,0,1-.251,1.566q-.191.777-.311,1.481a1.535,1.535,0,0,0,.225,1.189,1.065,1.065,0,0,0,.858.463,1.616,1.616,0,0,0,1.035-.318,1.915,1.915,0,0,0,.405-.388,3.291,3.291,0,0,0,.3-.465,1.84,1.84,0,0,1,.643-.695.94.94,0,0,1,.871-.05l.831.318a1.439,1.439,0,0,1,.725.506.837.837,0,0,1-.008.826,7.485,7.485,0,0,1-.739,1.356,5.633,5.633,0,0,1-1.462,1.44,5.946,5.946,0,0,1-1.55.785,5.081,5.081,0,0,1-1.7.271,3.881,3.881,0,0,1-1.637-.389,3.55,3.55,0,0,1-1.355-1.183,3.694,3.694,0,0,1-.6-1.26,4.689,4.689,0,0,1-.14-1.211,5.468,5.468,0,0,1,.143-1.149q.132-.56.246-1.057a7,7,0,0,0,.155-.955,1.873,1.873,0,0,0-.1-.835l-.093-.238a.927.927,0,0,1-.047-.672,1.294,1.294,0,0,1,.492-.551Z" transform="translate(-161.602 -183.057)" fill="#fdf6da"/>
        <path id="Path_977" dataname="Path 977" d="M219.573,428.3a.872.872,0,0,1-.947,1.28l-1.542-.025a1.2,1.2,0,0,1-1.26-.966l-2.183-7.46q-.1-.351-.193-.765t-.173-.763q-.075-.413-.151-.845h-.041q-.089.43-.178.84-.088.348-.2.757t-.218.758l-2.465,7.384a1.2,1.2,0,0,1-1.29.925l-1.481-.024a.872.872,0,0,1-.9-1.311l4.6-12.43a1.267,1.267,0,0,1,1.311-.884l1.809.029a1.266,1.266,0,0,1,1.281.926Z" transform="translate(-100.213 -201.531)" fill="#fdf6da"/>
        <path id="Path_978" dataname="Path 978" d="M197.787,427.295a1,1,0,0,1-1.246,1l-1.329-.143a1,1,0,0,1-1-1.246l1.337-12.369a1,1,0,0,1,1.246-1l1.329.144a1,1,0,0,1,1,1.246Z" transform="translate(-94.357 -200.923)" fill="#fdf6da"/>
        <path id="Path_979" dataname="Path 979" d="M178,424.441a1,1,0,0,1-1.331.887l-6.816-1.364a1,1,0,0,1-.887-1.331l.161-.807a1,1,0,0,1,1.331-.887l4.4.88.524-2.621-3.287-.658a1,1,0,0,1-.887-1.33l.161-.807a1,1,0,0,1,1.331-.887l3.287.658.544-2.723-4.678-.936a1,1,0,0,1-.887-1.331l.161-.806a1,1,0,0,1,1.331-.888l7.1,1.42a1,1,0,0,1,.887,1.332Z" transform="translate(-82.081 -198.946)" fill="#fdf6da"/>
        <path id="Path_980" dataname="Path 980" d="M145.62,416.981a1.028,1.028,0,0,1-.437.825,1.049,1.049,0,0,1-.924.008l-1.369-.507a1.139,1.139,0,0,1-.806-1.374l1.3-8.246q.064-.349.122-.624c.039-.182.076-.341.11-.475s.083-.283.131-.412l-.039-.014a2.437,2.437,0,0,1-.189.391q-.109.177-.235.428a3.7,3.7,0,0,1-.343.541l-4.743,6.993a1.179,1.179,0,0,1-1.52.489l-.829-.307a1.149,1.149,0,0,1-.813-1.355l1-8.38q.045-.356.1-.63t.092-.482a2.438,2.438,0,0,1,.111-.419l-.038-.015c-.048.128-.1.261-.17.4s-.133.268-.216.435a5.173,5.173,0,0,1-.324.549l-4.531,7.071a1.144,1.144,0,0,1-1.52.489l-1.311-.486a1.051,1.051,0,0,1-.7-.609,1.027,1.027,0,0,1,.206-.91l7.311-10.6a1.173,1.173,0,0,1,1.512-.47l1.87.694a1.178,1.178,0,0,1,.833,1.361l-.735,6.593a4.351,4.351,0,0,1-.1.677q-.072.313-.113.54c-.035.176-.076.344-.121.5l.039.014q.106-.224.237-.461.215-.4.634-1.059l3.742-5.477a1.179,1.179,0,0,1,1.52-.489l1.909.708a1.178,1.178,0,0,1,.832,1.362Z" transform="translate(-61.951 -193.951)" fill="#fdf6da"/>
        <path id="Path_981" dataname="Path 981" d="M103.855,397.031a1,1,0,0,1-1.563.338l-3.6-2.316a7.781,7.781,0,0,1-1.57-1.3,4.692,4.692,0,0,1-.927-1.466,3.408,3.408,0,0,1-.213-1.568,3.6,3.6,0,0,1,.591-1.6,3.953,3.953,0,0,1,1.332-1.27,3.077,3.077,0,0,1,1.8-.434l.023-.034a2.923,2.923,0,0,1-.6-1.037,3.308,3.308,0,0,1,.024-2.089,4.532,4.532,0,0,1,1.933-2.375,3.824,3.824,0,0,1,1.767-.464,5.39,5.39,0,0,1,1.91.338,9.3,9.3,0,0,1,1.9.942l3.6,2.316a1,1,0,0,1,.339,1.563Zm-2.109-6.789a1.151,1.151,0,0,0-1.052-.189,1.532,1.532,0,0,0-.772.628,1.2,1.2,0,0,0,.462,1.79l1.5.968,1.414-2.2Zm2.947-5.146a1.448,1.448,0,0,0-1.236-.246,1.627,1.627,0,0,0-.913.7,1.575,1.575,0,0,0-.254,1.1,1.453,1.453,0,0,0,.738,1l1.832,1.18,1.648-2.559Z" transform="translate(-46.63 -185.321)" fill="#fdf6da"/>
        <path id="Path_982" dataname="Path 982" d="M80.991,380.845a1,1,0,0,1-1.593.147l-5.346-4.442a1,1,0,0,1-.147-1.593l.525-.633a1,1,0,0,1,1.593-.148l3.448,2.866,1.708-2.057L78.6,372.844a1,1,0,0,1-.148-1.593l.526-.633a1,1,0,0,1,1.593-.147l2.578,2.142,1.774-2.135-3.669-3.049a1,1,0,0,1-.148-1.593l.526-.632a1,1,0,0,1,1.593-.147l5.568,4.626a1,1,0,0,1,.147,1.593Z" transform="translate(-35.75 -177.215)" fill="#fdf6da"/>
        <path id="Path_983" dataname="Path 983" d="M60.288,362.639a1,1,0,0,1-1.6-.063L57.6,361.4a1.453,1.453,0,0,1-.362-1.513l2.032-6.8a8.755,8.755,0,0,1,.3-.862l.339-.845q.194-.457.371-.9l-.028-.03-.609.674q-.259.3-.562.6t-.56.545L54.3,356.158a1,1,0,0,1-1.6-.063l-.907-.982a1,1,0,0,1,.064-1.6L61,345.073a1,1,0,0,1,1.6.064l1.088,1.178a1.433,1.433,0,0,1,.348,1.5l-2,6.8a8.819,8.819,0,0,1-.275.869q-.17.467-.346.852-.179.473-.385.887l.028.03.609-.674q.26-.3.563-.6t.559-.545l4.2-3.879a1,1,0,0,1,1.6.063l.907.982a1,1,0,0,1-.064,1.6Z" transform="translate(-24.988 -167.486)" fill="#fdf6da"/>
        <path id="Path_984" dataname="Path 984" d="M41.567,340.693a1,1,0,0,1-1.576-.273l-4-5.682a1,1,0,0,1,.274-1.576l.672-.474a1,1,0,0,1,1.576.273l2.582,3.665,2.185-1.54-1.931-2.74a1,1,0,0,1,.273-1.576l.672-.474a1,1,0,0,1,1.576.273l1.931,2.74,2.27-1.6-2.748-3.9a1,1,0,0,1,.273-1.576l.672-.474a1,1,0,0,1,1.576.273l4.169,5.917a1,1,0,0,1-.272,1.576Z" transform="translate(-17.355 -158.15)" fill="#fdf6da"/>
        <path id="Path_985" dataname="Path 985" d="M28.408,324.321a1,1,0,0,1-1.536-.445l-3.253-5.909a1,1,0,0,1,.445-1.537l.721-.4a1,1,0,0,1,1.536.445l2.063,3.747,2.7-1.488L29.469,315.8a1,1,0,0,1,.446-1.537l.72-.4a1,1,0,0,1,1.536.446l1.617,2.936,3.783-2.083a1,1,0,0,1,1.537.446l.644,1.171a1,1,0,0,1-.445,1.537Z" transform="translate(-11.382 -152.409)" fill="#fdf6da"/>
        <path id="Path_986" dataname="Path 986" d="M20.385,305.924a1,1,0,0,1-1.492-.576l-.541-1.222a1,1,0,0,1,.576-1.492L30.3,297.6a1,1,0,0,1,1.492.576l.541,1.222a1,1,0,0,1-.576,1.492Z" transform="translate(-8.849 -144.528)" fill="#fdf6da"/>
        <path id="Path_987" dataname="Path 987" d="M13.265,286.341l1.05,3.161a1,1,0,0,1-.717,1.43l-.78.26a1,1,0,0,1-1.43-.717l-3.236-9.738a1,1,0,0,1,.716-1.43l.781-.259a1,1,0,0,1,1.43.717l1.05,3.161,9.953-3.307a1,1,0,0,1,1.43.717l.421,1.268a1,1,0,0,1-.717,1.43Z" transform="translate(-3.918 -135.541)" fill="#fdf6da"/>
        <path id="Path_988" dataname="Path 988" d="M18.251,261.3a1.557,1.557,0,0,1-.215.337.928.928,0,0,1-.308.241.88.88,0,0,1-.413.083,1.487,1.487,0,0,1-.555-.15l-1.033-.441a1.032,1.032,0,0,1-.629-.565,1.458,1.458,0,0,1,.057-.914l.154-.482q.091-.281.174-.644a4.315,4.315,0,0,0,.1-.787,3.6,3.6,0,0,0-.075-.887,1.911,1.911,0,0,0-.527-1.1.9.9,0,0,0-.791-.228,1.064,1.064,0,0,0-.76.647,6.273,6.273,0,0,0-.426,1.314q-.17.769-.355,1.666a7.626,7.626,0,0,1-.566,1.709,4.739,4.739,0,0,1-1.042,1.439,3.554,3.554,0,0,1-1.788.855,4.193,4.193,0,0,1-1.884-.028,4.322,4.322,0,0,1-1.619-.794,4.9,4.9,0,0,1-1.228-1.451,6.6,6.6,0,0,1-.725-1.973,7.386,7.386,0,0,1-.117-2.389,6.648,6.648,0,0,1,.4-1.717,1.141,1.141,0,0,1,.593-.645,1.366,1.366,0,0,1,.849,0l1.045.292q.646.184.736.575a1.694,1.694,0,0,1-.046.859q-.14.553-.243,1.13a3.411,3.411,0,0,0,.04,1.282,1.987,1.987,0,0,0,.537,1.15.906.906,0,0,0,.789.218,1.054,1.054,0,0,0,.771-.649,6.242,6.242,0,0,0,.406-1.31q.16-.766.325-1.66a7.2,7.2,0,0,1,.536-1.7,4.619,4.619,0,0,1,1.031-1.436,3.563,3.563,0,0,1,1.789-.855,4.617,4.617,0,0,1,1.791-.016,4.173,4.173,0,0,1,1.629.687,4.828,4.828,0,0,1,1.3,1.394,6.11,6.11,0,0,1,.8,2.083,7.469,7.469,0,0,1,.155,1.49,9.636,9.636,0,0,1-.092,1.361,8.028,8.028,0,0,1-.247,1.162,5.192,5.192,0,0,1-.32.852" transform="translate(-1.765 -122.535)" fill="#fdf6da"/>
        <path id="Path_989" dataname="Path 989" d="M1.422,229.411A1,1,0,0,1,.25,228.322L0,221.582a1,1,0,0,1,1.089-1.172l.822-.03a1,1,0,0,1,1.171,1.088l.158,4.275,3.082-.114-.123-3.35a1,1,0,0,1,1.089-1.172l.822-.03a1,1,0,0,1,1.172,1.089l.123,3.349,4.316-.159a1,1,0,0,1,1.172,1.089l.049,1.336a1,1,0,0,1-1.088,1.172Z" transform="translate(0 -107.078)" fill="#fdf6da"/>
        <path id="Path_990" dataname="Path 990" d="M7.374,202.314a7.783,7.783,0,0,1-2.885-.836,7.137,7.137,0,0,1-2.224-1.795A7.266,7.266,0,0,1,.913,197.1a8.771,8.771,0,0,1,.6-6.223,7.273,7.273,0,0,1,1.82-2.273,7.126,7.126,0,0,1,2.525-1.337A7.787,7.787,0,0,1,8.849,187a8.125,8.125,0,0,1,2.956.843,7.283,7.283,0,0,1,2.275,1.8,7.379,7.379,0,0,1,1.394,2.582,8.491,8.491,0,0,1-.6,6.223,7.361,7.361,0,0,1-1.862,2.269,7.263,7.263,0,0,1-2.576,1.332,8.115,8.115,0,0,1-3.062.263m.357-3.705A4.973,4.973,0,0,0,9.5,198.48a4.308,4.308,0,0,0,1.461-.686A3.837,3.837,0,0,0,12,196.634a3.912,3.912,0,0,0,.305-3.173,3.821,3.821,0,0,0-.8-1.338,4.314,4.314,0,0,0-1.3-.952,4.978,4.978,0,0,0-1.711-.465,4.612,4.612,0,0,0-1.7.136,4.132,4.132,0,0,0-1.41.69,3.728,3.728,0,0,0-1,1.163,4.143,4.143,0,0,0-.306,3.173,3.726,3.726,0,0,0,.76,1.334,4.136,4.136,0,0,0,1.252.947,4.609,4.609,0,0,0,1.64.458" transform="translate(-0.289 -90.842)" fill="#fdf6da"/>
        <path id="Path_991" dataname="Path 991" d="M5.559,169.5a1,1,0,0,1-.825-1.371l.964-3.872a14.574,14.574,0,0,1,.5-1.686,4.714,4.714,0,0,1,.525-1,4.029,4.029,0,0,1,2.006-1.545,4.789,4.789,0,0,1,2.8-.055,5.328,5.328,0,0,1,1.115.415,4.857,4.857,0,0,1,1,.672,4.311,4.311,0,0,1,.792.907,3.2,3.2,0,0,1,.455,1.109l.04.01c.063-.027.142-.057.234-.09a1.882,1.882,0,0,1,.312-.1q.192-.048.446-.133l4.178-1.121a.923.923,0,0,1,.975.126.97.97,0,0,1,.153.96l-.378,1.517a1.449,1.449,0,0,1-1.1,1.1L15.2,166.45l-.407,1.636,3.712.924a1,1,0,0,1,.824,1.371l-.323,1.3a1,1,0,0,1-1.371.824Zm6.615-3.672a1.945,1.945,0,0,0-.118-1.438,1.739,1.739,0,0,0-1.186-.814,2.377,2.377,0,0,0-.97-.062,1.182,1.182,0,0,0-.756.522,3.526,3.526,0,0,0-.527,1.268l-.293,1.177,3.472.864Z" transform="translate(-2.274 -77.636)" fill="#fdf6da"/>
        <path id="Path_992" dataname="Path 992" d="M24.665,131.3l-8.356.721a.92.92,0,0,1-.937-.3.969.969,0,0,1,.021-.972l.668-1.481a1.348,1.348,0,0,1,1.309-.853l2.98-.054c.282.007.558.011.829.013s.516.007.737.016c.265.014.507.026.729.035l.017-.038q-.231-.239-.509-.522-.231-.239-.5-.532c-.184-.2-.368-.4-.555-.622l-1.94-2.251a1.35,1.35,0,0,1-.228-1.547l.668-1.481a.971.971,0,0,1,.715-.659q.445-.059.844.5l4.992,6.74,4.463,2.012a1,1,0,0,1,.565,1.5l-.549,1.219a1,1,0,0,1-1.5.566Z" transform="translate(-7.411 -58.678)" fill="#fdf6da"/>
        <path id="Path_993" dataname="Path 993" d="M34.738,110.847a7.769,7.769,0,0,1-2.2-2.044,7.126,7.126,0,0,1-1.179-2.6,7.257,7.257,0,0,1-.048-2.911A8.776,8.776,0,0,1,34.644,98a7.27,7.27,0,0,1,2.648-1.211,7.139,7.139,0,0,1,2.857-.059,7.771,7.771,0,0,1,2.793,1.1A8.115,8.115,0,0,1,45.2,99.917a7.267,7.267,0,0,1,1.223,2.631,7.358,7.358,0,0,1,.083,2.934,8.493,8.493,0,0,1-3.334,5.288,7.363,7.363,0,0,1-2.683,1.19,7.282,7.282,0,0,1-2.9.031,8.123,8.123,0,0,1-2.853-1.142m1.985-3.149a4.978,4.978,0,0,0,1.638.68,4.3,4.3,0,0,0,1.612.045,3.826,3.826,0,0,0,1.452-.568,3.911,3.911,0,0,0,1.7-2.7,3.82,3.82,0,0,0-.114-1.555,4.3,4.3,0,0,0-.735-1.436,5,5,0,0,0-1.32-1.185,4.622,4.622,0,0,0-1.577-.642,4.141,4.141,0,0,0-1.57-.017,3.725,3.725,0,0,0-1.417.59,4.144,4.144,0,0,0-1.7,2.7,3.71,3.71,0,0,0,.079,1.533,4.136,4.136,0,0,0,.692,1.409,4.6,4.6,0,0,0,1.259,1.146" transform="translate(-15.153 -46.943)" fill="#fdf6da"/>
        <path id="Path_994" dataname="Path 994" d="M48.052,77.878a1,1,0,0,1-.108-1.6l.879-1.007a1,1,0,0,1,1.6-.108l6.15,5.369a2.211,2.211,0,0,0,1.786.644,3.2,3.2,0,0,0,2.448-2.8,2.214,2.214,0,0,0-.879-1.682l-6.15-5.37a1,1,0,0,1-.108-1.6l.879-1.007a1,1,0,0,1,1.6-.108l6.15,5.37a5.746,5.746,0,0,1,1.421,1.827,5.219,5.219,0,0,1,.53,2.114,5.7,5.7,0,0,1-.389,2.227A7.135,7.135,0,0,1,62.52,82.32a7.283,7.283,0,0,1-1.988,1.635,5.676,5.676,0,0,1-2.161.693A5.213,5.213,0,0,1,56.2,84.41a5.737,5.737,0,0,1-2-1.162Z" transform="translate(-23.127 -33.17)" fill="#fdf6da"/>
      </g>
    </g>
    <g id="Group_967" dataname="Group 967" transform="matrix(0.485, -0.875, 0.875, 0.485, 6837.48, 6366.153)">
      <path id="Path_995" dataname="Path 995" d="M320.187,44.138a35.263,35.263,0,0,1-3.327,3.147l1.23-.159a30.036,30.036,0,0,1-6.89-6.936,12.422,12.422,0,0,1-2.491-6.115,3.571,3.571,0,0,1,1.943-3.648,2.423,2.423,0,0,1,2.455-.053,11.08,11.08,0,0,1,3.332,3.512,1.027,1.027,0,0,0,1.753,0,8.555,8.555,0,0,1,3.8-3.778,2.48,2.48,0,0,1,1.571-.329,3.364,3.364,0,0,1,1.7,1.319c.909,1.33.639,3.115.206,4.561-.638,2.13-3.942,6.683-5.234,8.474-.765,1.061,1,2.076,1.753,1.025,2.415-3.35,7.943-11.991,4.485-15.789a4.249,4.249,0,0,0-5-1.24,10.352,10.352,0,0,0-5.034,4.731h1.753c-1.336-1.956-3.384-4.786-6.038-4.866a5.708,5.708,0,0,0-4.562,2.456c-2.34,3.465.051,8.263,2.156,11.194a33.667,33.667,0,0,0,7.318,7.234,1.028,1.028,0,0,0,1.23-.159,35.262,35.262,0,0,0,3.327-3.147c.89-.955-.543-2.395-1.435-1.435" transform="translate(8586.572 -2430.732)" fill="#fdf6da"/>
      <path id="Path_996" dataname="Path 996" d="M8912.213-2390.008l-3.791-4.486h-2.9l-2.363,1.748-.651,3.224,3.33,7.435,3.419,3.408,2.956,2.061,7.317-9.256,1.576-5.8-.975-2.323-1.806-1.381h-2.01l-3.233,2.17Z" transform="translate(-7.838 -6.382)" fill="#fdf6da"/>
    </g>
  </g>
  </g>
</svg>


)