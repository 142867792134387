import Button from 'Components/Button/Button'
import { reportEvent } from 'Utils/analytics'

import './Slide.sass'

function Slide({
    slide,
    isActive,
}) {
    const {
        title,
        copy,
        link,
        image,
        benefitPillar,
    } = slide

    const label = <>
        <span dangerouslySetInnerHTML={{ __html: link ? link.title : 'View' }} />
        <i className="fe fe-play" />
    </>

    const _benefitPillar = benefitPillar ? benefitPillar[0].slug : null

    const clickButtonCallback = () => {
        reportEvent({
            category: "User Interaction",
            action: `Clicked ${title}`
        })
    }

    return (
        <div className={`slide`}>
            <div className={`slide-inner ${_benefitPillar}`}>
                <div className={`slide-main`}>
                    { title && <h1 dangerouslySetInnerHTML={{ __html: title }} /> }
                    { copy && <div className='copy' dangerouslySetInnerHTML={{ __html: copy }} /> }
                </div>
                <div
                    className={`slide-image`}
                    style={{
                        backgroundImage: image ? `url(${image.mediaItemUrl})` : null,
                    }}
                />
                { link &&
                    <div
                        onClick={clickButtonCallback}
                        className="buttonCont"
                    >
                        <Button
                            label={label}
                            href={link.url}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default Slide
