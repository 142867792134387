import './FeelGoodSection.sass'
import arrow from 'Images/arrow'

export default function FeelGoodSection({
    title,
    copy,
    optionsTitle,
    options
}) {
    return (

        <div className="container">
            <div className="copyCont">
                { title  && <h2 dangerouslySetInnerHTML={{ __html: title }} /> }
                { copy && <div dangerouslySetInnerHTML={{ __html: copy }} className={"copy"} /> }
                { optionsTitle && <h4 dangerouslySetInnerHTML={{ __html: optionsTitle }} className="optionsTitle"/> }
            </div>
            <div className="optionsBlock" data-title={optionsTitle}>
                { options && options.map((option, index) => { 
                    const {
                        title,
                        url,
                        icon,
                        backgroundColour
                    } = option
                    return (
                        <div className={"option"} key={index} style={{ backgroundColor: backgroundColour}}>
                            <a href={url ? url : "/"} target="_blank">
                                { icon && <img src={icon.mediaItemUrl} alt={title} /> }
                                { title && <h4 dangerouslySetInnerHTML={{ __html: title }} /> }
                            </a>
                            <div className="arrow">
                                <span>Learn more</span>
                                {arrow}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>

    )
}