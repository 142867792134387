export default (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 234.552 141.179">
        <defs>
            <clipPath id="clipPath">
            <path id="Path_451" dataname="Path 451" d="M0,137.147H232.194V0H0Z" transform="translate(0 0)" fill="#fff"/>
            </clipPath>
        </defs>
        <g id="Group_765" dataname="Group 765" transform="translate(2.394) rotate(1)">
            <g id="Group_665" dataname="Group 665" transform="translate(0 0)" clipPath="url(#clipPath)">
            <g id="Group_658" dataname="Group 658" transform="translate(0 29.27)">
                <path id="Path_444" dataname="Path 444" d="M13.865,20.558c.322,2.256-1.085,4.5-4.47,4.985-3.824.546-8.434-3.53-9.238-9.172C-.843,9.35,2.929,2.159,11.893.883A47.054,47.054,0,0,1,21.941.475C38.141,1.044,42.967.8,49.057,0a4.188,4.188,0,0,1,1.092,2.274c.562,3.95-2.885,7.128-7.587,7.8a32.407,32.407,0,0,1-8.374.041,60.2,60.2,0,0,0-.818,9.519,24.109,24.109,0,0,0,2.74-.07,22.893,22.893,0,0,0,6.449-1.879,3.636,3.636,0,0,1,1.477,2.284c.589,4.138-2.259,6.59-6.522,7.2a25.627,25.627,0,0,1-4.344.044c-.036,1.092-.62,6.869-.683,7.772C31.571,46.5,27.152,55.442,18.25,56.711c-6.269.894-10.092-2.145-10.868-7.6-.67-4.7,1.525-10.387,14.97-22.41a4.636,4.636,0,0,1-2.183-3.2,3.062,3.062,0,0,1,1.3-3,5.438,5.438,0,0,1,2.123-.815A35.3,35.3,0,0,1,26.272,9.323c-6.949-1.185-11.56-1.231-14.945-.749s-4.72,2.336-4.3,5.281c.356,2.508,2.157,4.809,6.838,6.7M15.217,47.1c.367,2.57,1.441,3.377,2.82,3.181,2.319-.331,4.031-5.82,4.448-16.816-6.253,7.735-7.6,11.317-7.268,13.635" fill="#fff"/>
            </g>
            <g id="Group_659" dataname="Group 659" transform="translate(44.953 37.216)">
                <path id="Path_445" dataname="Path 445" d="M22.838,25.2c-3.09,4.343-7.649,8.254-12.227,8.906-6.143.875-9.464-2.234-10.428-9C-1.167,15.639,5.222,5.263,11.3,4.4c4.138-.589,6.608,1.488,7.127,5.124.733,5.14-2.3,9.474-9.6,12.945.4,2.821,1.189,4.308,3.634,3.96,3.637-.519,6.834-3.659,9.468-8.064C22.27,9.548,27.888.944,33.655.122c4.327-.616,7.253,1.078,7.879,5.466.75,5.265-2.332,10.565-10.644,13.733.537,3.762,1.977,5.346,4.046,5.053,4.953-.706,9.136-5.908,12.085-12.149a2.039,2.039,0,0,1,2.427,1.766c.687,4.827-7.725,17.155-16.315,18.378-5.328.761-8.721-1.955-10.3-7.167m-12.165-14c-.878.125-2.32,2.121-2.134,6.572,3.188-1.413,3.96-2.739,3.683-4.682-.189-1.318-.8-2-1.549-1.891M33.027,6.927c-1.318.189-2.589,2.929-2.421,7.7,3.421-1.126,4.425-3.957,4.156-5.837-.187-1.318-.984-1.971-1.735-1.864" fill="#fff"/>
            </g>
            <g id="Group_660" dataname="Group 660" transform="translate(90.562 17.8)">
                <path id="Path_446" dataname="Path 446" d="M1.107,15.118C1.03,6.5,4.757.783,9.709.077c2.7-.384,4.567.629,5.023,3.825.392,2.758-.424,6.009-3.865,15.518-.826,6.321-2.218,17.648-1.994,19.216.1.689.447.895,1.012.815,1.944-.277,5.132-4.377,7.668-9.919a2.317,2.317,0,0,1,2.686,1.791c.679,4.765-8.074,16.054-14.405,16.957C2.2,48.8.658,47.419.194,44.159c-.6-4.2.371-11.758.5-14.461.172-4.183.46-10.236.418-14.58" fill="#fff"/>
            </g>
            <g id="Group_661" dataname="Group 661" transform="translate(121.631 11.806)">
                <path id="Path_447" dataname="Path 447" d="M30.257,37.028c1.134,9.753-.053,22.521-12.152,24.246C9.517,62.5,2.57,58.628,1.6,51.856a6.269,6.269,0,0,1,3.263-6.732C2.422,43.234.874,40,.293,35.926-2.011,19.752,9.681,1.713,20.776.131c5.581-.794,8.1,2.107,8.73,6.5.8,5.581-1.831,10.943-5.717,11.5a2.89,2.89,0,0,1-2.795-.754,24.407,24.407,0,0,0,.3-6.438c-.339-2.383-.948-3.063-2.076-2.9C14.453,8.707,8.287,21.994,9.77,32.4c.589,4.138,2.944,5.849,6.142,5.393a13.871,13.871,0,0,0,5.32-2.165c-.642-8.1-1.564-10.971,3.012-11.624,3.449-.491,7.056.146,7.342,2.153s-.421,4.218-1.533,7.636ZM9.084,46.89c2.775,4.21,6.6,6.8,10.422,6.255,4.639-.662,3.01-5.354,2.449-11.095a16.511,16.511,0,0,1-8.966,4.859,12.113,12.113,0,0,1-3.905-.019" transform="translate(0)" fill="#fff"/>
            </g>
            <g id="Group_662" dataname="Group 662" transform="translate(156.489 22.553)">
                <path id="Path_448" dataname="Path 448" d="M47.876,4.029a35.068,35.068,0,0,1-.927,11.454,1.848,1.848,0,0,0,1.316.259c1.818-.259,3.43-1.96,5.026-5.577a2.478,2.478,0,0,1,2.766,1.908c.376,2.633-2.985,9.124-8.313,9.883a4.292,4.292,0,0,1-2.6-.27c-2.428,4.5-5.43,7.235-9.817,7.86-4.452.634-8.382-1.811-9.257-7.955a24.434,24.434,0,0,1,.459-9.339c-2.472.608-4.577,1.1-4.577,1.1-.557,8.2-4.618,18.76-12.453,19.877-4.45.634-8.382-1.811-9.257-7.955C-1.238,14.865,4.211,4.622,10.856,3.675c1.566-.223,3.554.261,3.9,2.258a4.327,4.327,0,0,1,3.279-2.577c2.131-.3,3.529.968,4.012,4.353,2.274-.645,5.408-1.538,7.308-2.129C32.527,2.251,35.134.343,37.266.04a2.8,2.8,0,0,1,3.321,2.213A3.734,3.734,0,0,1,43.454.373c2.57-.367,4.074,1.21,4.422,3.655M11.618,25.634c2.32-.331,3.566-5.946,3.631-11.327-.923-.189-1.719-1.29-1.933-2.795a6.35,6.35,0,0,1,.521-3.528c-3.468,2.157-5.655,9.248-4.958,14.138.42,2.946,1.737,3.654,2.739,3.512m25.846-4c1.505-.214,2.589-1.585,3.27-3.087a15.2,15.2,0,0,1-1.824-5.626,23.258,23.258,0,0,1,.531-7.5A10.127,10.127,0,0,1,35.6,8.976,18.524,18.524,0,0,0,34.645,18c.437,3.071,1.691,3.788,2.819,3.627" fill="#fff"/>
            </g>
            <g id="Group_663" dataname="Group 663" transform="translate(208.202 0)">
                <path id="Path_449" dataname="Path 449" d="M10.183,19.466c1.944-.277,3.036.656,3.268,2.284a3.167,3.167,0,0,1,.044.762c-4.156,3.6-5.216,10.529-4.582,14.98.3,2.131,1.021,2.669,2.274,2.49,2.634-.375,5.063-10.7,3.617-20.855C13.768,11.857,9.291,8.72,3.345,9.184a2.819,2.819,0,0,1-1.11-1.953C1.816,4.284,3.894.919,9.473.124,16.556-.887,22.1,4.336,23.448,13.8,26.226,33.3,17.866,47.345,9.9,48.48c-5.014.714-8.74-2.081-9.73-9.039-1.3-9.152,4.994-19.26,10.01-19.975" fill="#fff"/>
            </g>
            <g id="Group_664" dataname="Group 664" transform="translate(46.686 67.705)">
                <path id="Path_450" dataname="Path 450" d="M72.378,69.442A110.745,110.745,0,0,1,1.9,44.078a5.236,5.236,0,0,1,6.677-8.067,100.259,100.259,0,0,0,63.8,22.959A99.8,99.8,0,0,0,126.022,43.4,100.427,100.427,0,0,0,162.3,2.929a5.236,5.236,0,1,1,9.4,4.617,110.887,110.887,0,0,1-40.055,44.692,110.246,110.246,0,0,1-59.263,17.2" fill="#fff"/>
            </g>
            </g>
        </g>
    </svg>

)