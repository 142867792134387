import Moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { MainContext } from 'Utils/context'

export const useIsMobile = (breakPoint = 500) => {
    const [_isMobile, setIsMobile] = useState(false)

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        setTimeout(() => {
            if (_window) {
                setIsMobile(_window.innerWidth <= breakPoint)
            }
        }, 1)
    }, [_window, breakPoint])

    return _isMobile
}

export const useIsTablet = () => {
	const breakPoint = 1100

	const [_isTablet, setIsTablet] = useState(false)

	const _window = typeof window !== 'undefined' ? window : null

	useEffect(() => {
		setTimeout(() => {
			if (_window) {
				setIsTablet(_window.screen.width <= breakPoint && _window.screen.width > 500)
			}
		}, 1)
	}, [_window])

	return _isTablet
}

export const useMainContext = () => {
    return useContext(MainContext)
}


export const isShittyBrowser = () => {
    return !!window.document.documentMode
}

export const sortByHashTag = (collection, raw) => {
    const orders = {
        'customerobsessed': 0,
        'showheart': 1,
        'brilliancetogether': 2,
        'breakboundaries': 3
    }

    return collection.sort((a,b) => {
        const aIndex = orders[raw ? a.slug : a.hashtag[0].slug]
        const bIndex = orders[raw ? b.slug : b.hashtag[0].slug]

        return aIndex - bIndex
    })
}

export const getNew = createDate => {
    const todaysDate = Moment().startOf('day')
    const daysSinceCreate = Math.round(Moment.duration(todaysDate - Moment(createDate)).asDays())
    const isNew = daysSinceCreate <= 30

    return isNew
}

export const getUpdated = (createDate, modifiedDate) => {
    const todaysDate = Moment().startOf('day')
    const daysSinceCreate = Math.round(Moment.duration(todaysDate - Moment(createDate)).asDays())
    const isNew = daysSinceCreate <= 30
    const daysSinceModify = Math.round(Moment.duration(todaysDate - Moment(modifiedDate)).asDays())
    const isUpdated = daysSinceModify <= 30

    return !isNew && isUpdated
}
