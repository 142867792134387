export default (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 356.13 67.29">
        <path fill="#f5333f" d="M77.84,38.32H91.62v-.54c0-3.38-.36-5.16-1.42-6.84a5.78,5.78,0,0,0-5.26-2.67c-4.52,0-7.1,3.55-7.1,9.86v.19m21,15.55L103.44,61a26.13,26.13,0,0,1-17.15,6.3c-13.07,0-21.52-9.24-21.52-23.55,0-8.18,1.69-13.6,5.69-18,3.73-4.18,8.26-6.13,14.31-6.13,5.24,0,10.22,1.77,13.16,4.8,4.17,4.26,6,10.4,6,19.91V47H77.66v.35c0,7,3.46,11,9.6,11A17.73,17.73,0,0,0,98.81,53.87Z"/>
        <path fill="#f5333f" d="M338.44,20.27h14.31L338.66,39.81,356.13,66H342.08L326.79,40.18l11.65-19.91M314.61,66V1h11.92V66Z"/>
        <path fill="#f5333f" d="M255.14,62.58a7.74,7.74,0,0,1-3.62,2.91,15.87,15.87,0,0,1-6.74,1.62c-10.14,0-15.23-4.82-15.23-13.89,0-10.66,7.38-15.64,21.87-15.64.89,0,1.42,0,2.67.08V35.8c0-5.07-1-6.76-5.34-6.76-3.82,0-8.27,1.87-13.16,5.16l-5.06-8.54a33.81,33.81,0,0,1,7.37-3.73,31,31,0,0,1,12.45-2.67c7.65,0,12.89,2.85,14.67,7.91.62,1.87.81,3.29.81,8.19l-.28,15.29c0,.26,0,5.84,0,5.75,0,3.77.18,7.67,1.2,9.57H256.3a11.21,11.21,0,0,1-1.16-3.39m-1.23-17h-.53c-8.28,0-11.11,1.52-11.11,6.94,0,3.56,2.21,6,5.24,6a8.63,8.63,0,0,0,6.22-3.12Z"/>
        <path fill="#f5333f" d="M48.07,66V36c0-5.41-.62-6.48-3.72-6.48-2.22,0-5.32,1.51-7.9,3.82V66H25.19V36.42c0-5.67-.8-7-4.08-7a12.59,12.59,0,0,0-7.8,3.45V66H1.68V34.3c0-6.57-.44-9.41-1.68-11.62l10.73-2.93a11.41,11.41,0,0,1,1.6,4.61,15.67,15.67,0,0,1,11.09-4.61,12.3,12.3,0,0,1,9.22,3.72,15.26,15.26,0,0,1,1.77,2.22c4.17-4.26,7.9-5.94,12.87-5.94a14.06,14.06,0,0,1,8.95,2.84c2.58,2.21,3.37,4.88,3.37,11.09V66H48.07"/>
        <path fill="#00a7e1" d="M167.27,0a7.41,7.41,0,0,1,7.49,7.58,7.52,7.52,0,0,1-7.49,7.59,7.59,7.59,0,1,1,0-15.17m-4.14,29.06c0-1.77-.86-2.85-1.91-2.85-2.47,0-4.47,3.54-7.43,10.71l-3.71-1.57c4.19-10.81,8.67-16,15.43-16,6.28,0,10.09,4.62,10.09,12.68a43.43,43.43,0,0,1-1.71,10.61l-2.19,8.46a22.34,22.34,0,0,0-.95,5.79c0,2.17.95,3.35,2.47,3.35,2.67,0,5.05-3.84,7.62-11.4l3.52,1.76c-3,11.11-8,16.62-15.33,16.62-6.86,0-11.05-4.53-11.05-12.29,0-4.23,1.24-8.85,2.58-14.35l1.71-7A22.61,22.61,0,0,0,163.13,29.06Z"/>
        <path fill="#f5333f" d="M270.52,22.68l10.74-2.93A11.82,11.82,0,0,1,282.88,25a41.2,41.2,0,0,1,5.24-3.38,18.86,18.86,0,0,1,8.18-2c5.08,0,9.51,2.67,10.94,6.58a18.27,18.27,0,0,1,.88,6.5V66H296.39V36.36c0-5.16-.89-6.67-4-6.67-2.4,0-5.51,1.6-8.27,4.09V66H272.21V33c0-7.07-1.11-9.28-1.69-10.3"/>
        <path fill="#f5333f" d="M135.56,32.44c-2.22-2-4.35-2.93-6.49-2.93-5.33,0-7.64,4.44-7.64,14.58,0,9.78,2,13,8.27,13a8.47,8.47,0,0,0,5.86-2.94V32.44M137.93,66a13.23,13.23,0,0,1-.79-2.82c-2.59,2.11-6.66,4-10.11,4-11.56,0-18.85-9-18.85-23.12s7.91-24,19.47-24a12.22,12.22,0,0,1,8.08,2.58V1h11.48V66Z"/>
        <path fill="#f5333f" d="M212.62,34.93c-.89-3.91-3.38-5.78-6.49-5.78s-5.24,2-6.76,3.65v21c1.61,1.42,3.47,2.93,6.67,2.93,2.94,0,4.8-1.15,5.78-3.55s1.33-4.89,1.33-10.49a33,33,0,0,0-.53-7.74M199,1v22c2.31-2.49,5.81-3.65,9.59-3.65,11.11,0,18,8.89,18,23.12,0,9.25-2.85,16.45-8.45,20.89a15,15,0,0,1-10,3.56c-4.29,0-7.46-1.23-10-4.08a18.76,18.76,0,0,1-.78,3.19H187.37V1Z"/>
    </svg>
)
