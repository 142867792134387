import { useMainContext } from 'Utils'

import BenefitSliderType from './BenefitSliderType'

import './BenefitSlider.sass'

const BenefitSlider = ({
    title,
}) => {
    const { 
        benefitTypes,
    } = useMainContext()

    benefitTypes?.sort((a, b) => {
        if (a.databaseId < b.databaseId) return -1
        if (a.databaseId > b.databaseId) return 1
        return 0
    })

    return (
        <div className="BenefitSlider-cont">
            <div className="BenefitSlider-title">
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className="BenefitSlider-types">
                { benefitTypes && benefitTypes.map((benefitType, index) => {
                    return (
                        <BenefitSliderType
                            benefitType={ benefitType }
                            key={ index }
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default BenefitSlider
