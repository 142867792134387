import ReactGA from 'react-ga4'

export const initGA = () => {
    if (!window.GA_INITIALISED) {
        ReactGA.initialize('G-H9VXMB2KNW')
        window.GA_INITIALISED = true
    }
}

export const pageview = () => {
    ReactGA.send("pageview")
}

export const reportEvent = props => {
    ReactGA.event({
        ...props
    })
}