import { useInView } from 'react-intersection-observer'
import {
    isShittyBrowser,
    useMainContext
} from 'Utils'

const FlexibleContentRow = ({
    Component,
    row,
    className
}) => {
    const { ref, inView } = useInView({
        threshold: 0.5,
    })

    const { isMobile } = useMainContext()

    const _isShittyBrowser = isShittyBrowser()

    return (
        <div
            className={`${className} ${(inView || _isShittyBrowser || isMobile) ? 'in-view' : ''}`}
            ref={ref}
        >
            <Component
                {...row}
            />
        </div>
    )
}

export default FlexibleContentRow