export const GET_BENEFIT_BY_BENEFITTYPE = `
    query MyQuery (
        $terms: [String]
    ) {
        benefits(
            where: {
                taxQuery: {
                    taxArray: {
                        field: SLUG,
                        terms: $terms,
                        taxonomy: BENEFITTYPE
                        operator: IN
                    }
                }
            },
            first: 500
        ) {
            edges {
                node {
                    id
                    date
                    modified
                    title
                    slug
                    content
                    benefitFields {
                        excerpt
                        icon
                        button {
                            url
                            title
                        }
                        program
                    }
                    benefitCategories {
                        edges {
                            node {
                                slug
                                name
                                categoryFields {
                                    colourScheme
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GET_BENEFIT_BY_BENEFITPILLAR = `
    query MyQuery (
        $terms: [String]
    ) {
        benefits(
            where: {
                taxQuery: {
                    taxArray: {
                        field: SLUG,
                        terms: $terms,
                        taxonomy: BENEFITPILLAR
                        operator: IN
                    }
                }
            },
            first: 500
        ) {
        edges {
            node {
                id
                date
                modified
                title
                slug
                content
                benefitFields {
                    excerpt
                    icon
                    button {
                        url
                        title
                    }
                    program
                }
                benefitCategories {
                    edges {
                            node {
                                slug
                                name
                                categoryFields {
                                    colourScheme
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
