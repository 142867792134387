import React, { useState } from 'react'
import { useIsMobile, useIsTablet } from 'Utils'

import { useQuery } from 'urql'

export const MainContext = React.createContext()

const INITIAL_DATA = `
    query {
        options {
            options {
                quizCta {
                    copy
                    buttonTitle
                    title
                    image {
                        mediaItemUrl
                    }
                }
                heroTitle
                heroCopy
                newTagColour
                updatedTagColour
                suggestions {
                    pillar {
                        ... on BenefitPillar {
                            name
                            slug
                        }
                    }
                    categories {
                        ... on BenefitCategory {
                            name
                            slug
                        }
                    }
                }
            }
        }
        benefitPillars {
            nodes {
                description
                name
                slug
            }
        }
        benefitTypes {
            nodes {
                databaseId
                description
                name
                slug
                typeFields {
                    icon
                    backgroundColour
                    textColour
                }
            }
        }
        benefitCategories {
            nodes {
                description
                name
                slug
            }
        }
    }
`

export const ContextProvider = ({
    children,
}) => {
    const [modalData, setModalData] = useState(false)

    const [selectedPillar, setSelectedPillar] = useState({
        index: 0,
        name: "Health & Wellbeing",
        slug: "health-and-wellbeing",
    })

    const [searchInput, setSearchInput] = useState("")

    const openModal = props => {
        setModalData({
            ...props,
            isVisible: true
        })
    }

    const closeModal = () => {
        setModalData({
            ...modalData,
            isVisible: false
        })
    }

    const [{ data }] = useQuery({
        query: INITIAL_DATA
    })

    const isMobile = useIsMobile()
    const isTablet = useIsTablet()

    return (
        <MainContext.Provider
            value={{
                openModal,
                closeModal,
                modalData,
                isMobile,
                isTablet,
                options: data ? data.options.options : null,
                pillars: data ? data.benefitPillars.nodes : null,
                benefitTypes: data ? data.benefitTypes.nodes : null,
                benefitCategories: data ? data.benefitCategories.nodes : null,
                selectedPillar,
                setSelectedPillar,
                searchInput,
                setSearchInput,
            }}
        >
            {children}
        </MainContext.Provider>
    )
}
