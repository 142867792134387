import React, {useState, useEffect} from 'react'
import { useMainContext, getNew, getUpdated } from 'Utils'

import CategoryTag from 'Components/CategoryTag/CategoryTag'
import ViewButtons from './ViewButtons'
import Tag from 'Components/Tag/Tag'

import './SearchResults.sass'

import { useQuery } from "urql"
import { GET_BENEFIT_BY_BENEFITPILLAR } from 'Utils/queries'

import icons from 'Utils/icons'

function SearchResults() {
    const {
        searchInput,
        selectedPillar,
        openModal,
    } = useMainContext()

    const [active, setActive] = useState(false)
    const [viewType, setViewType] = useState('')

    useEffect(() => {
        if (!!searchInput) setTimeout(() => {
            setActive(true) 
        }, 1)
    }, [searchInput])

    const formattedPillar = {
		terms: selectedPillar.slug
	}

    const [{
        data,
    }] = useQuery({
        query: GET_BENEFIT_BY_BENEFITPILLAR,
        variables: formattedPillar,
    })

    const benefits = data?.benefits.edges.map(benefit => {
        const {node: {id, date, modified,  slug, title, benefitCategories, benefitFields, content}} = benefit
        const category = benefitCategories.edges[0]?.node
        const {button, excerpt, icon, program } = benefitFields

        return {
            id,
            date,
            modified,
            slug,
            title,
            category,
            button,
            excerpt,
            icon,
            program,
            content,
        }
    })

    const filteredBenefits = searchInput !== 'Showing all...' ? benefits?.filter(benefit => {
        return benefit.title?.toLowerCase().includes(searchInput.toLowerCase())
            || benefit.content?.toLowerCase().includes(searchInput.toLowerCase())
            || benefit.excerpt?.toLowerCase().includes(searchInput.toLowerCase())
            || benefit.category?.name.toLowerCase().includes(searchInput.toLowerCase())
    }) : benefits
    
    if (!filteredBenefits) return null

    const newBenefits = filteredBenefits.filter(benefit => {
        return getNew(benefit.date)
    })

    const updatedBenefits = filteredBenefits.filter(benefit => {
        return getUpdated(benefit.date, benefit.modified)
    })

    const _filteredBenefits = viewType === 'new' ? newBenefits : viewType === 'updated' ? updatedBenefits : filteredBenefits

    const handleViewSearchResults = (view) => {
        setViewType(view)
    }

    if (!searchInput) return null

    return (
        <div className="SearchResults">
            <div className={`container ${active ? 'active' : ''}`}>
                <div className="SearchResults-top">
                    <h2 dangerouslySetInnerHTML={{ __html: searchInput === 'Showing all...' ? searchInput : `Showing results for '${searchInput}'`}} />
                    <ViewButtons
                        handleViewSearchResults={ handleViewSearchResults }
                        viewType={ viewType }
                    />
                </div>
                <ul className="SearchResults-resultsGrid">
                    {_filteredBenefits && _filteredBenefits.map(benefit => {
                        const {
                            id,
                            date,
                            modified,
                            title,
                            category,
                            excerpt,
                            icon,
                            program,
                        } = benefit
            
                        return (
                            <li 
                                key={id}
                                className={`${program ? 'program' : ''}`}
                                onClick={() => openModal({
                                    type: "BENEFIT",
                                    data: benefit
                                })}
                            >
                                {category &&
                                    <div className='categoryTagCont'>
                                        <CategoryTag category={ category } />
                                    </div>
                                }
                                {program && 
                                    <div className="programBanner">
                                        <span>Program</span>
                                    </div>
                                }
                                <div className="iconCont">
                                    {icon && icons[icon] }
                                </div>
                                <div className="copyCont">
                                    <div className="titleCont">
                                        <h4 dangerouslySetInnerHTML={{ __html: title }} />
                                        <Tag created={date} modified={modified} />
                                    </div>
                                    <div className="copy small" dangerouslySetInnerHTML={{ __html: excerpt }} />
                                </div>
                                <div className="openModal">
                                    <i className="fe fe-arrow-right" />
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default SearchResults
