import Button from 'Components/Button/Button'
import './ImageCta.sass'

import { reportEvent } from 'Utils/analytics'

export default function ImageCta({
    title,
    copy,
    image,
    button,
    orientation,
}) {

    const clickButtonCallback = () => {
        const isSustainability = title.includes("Sustainability")
        reportEvent({
            category: "User Interaction",
            action: `Clicked on ${isSustainability ? 'Sustainability' : 'Work Reinvented'} button`
        })
    }
    return (
        <div className={"ImageCta-outer " + `${orientation}`}>
            <div className={"container"}>
                <div className="ImageCta-content">
                    { title && <h2 dangerouslySetInnerHTML={{ __html: title }} /> }
                    { copy && <div dangerouslySetInnerHTML={{ __html: copy }} className={"copy"} /> }
                    { button && 
                        <div onClick={clickButtonCallback}>
                            <Button href={button.url} className="button" label={button.title} />
                        </div>
                    }
                </div>
                <div className="ImageCta-imageCont">
                    <img src={image.mediaItemUrl} alt={""} />
                </div>
            </div>
        </div>
    )
}