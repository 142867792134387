import './Hero.sass'

import { useMainContext } from 'Utils'

import FeelGood from 'Images/PillarLogos/FeelGood'
import ImpactBigger from 'Images/PillarLogos/ImpactBigger'
import StepInStepUp from 'Images/PillarLogos/StepInStepUp'

import ShowAll2 from 'Images/showAll2'
import Star from 'Images/star'
import Arrow from 'Images/arrow'

import Suggestions from './Suggestions'

const logos = {
    "health-and-wellbeing": FeelGood,
    "learning-and-growing": ImpactBigger,
    "meaningful-work": StepInStepUp
}

const Hero = () => {

    const { 
        pillars,
        selectedPillar,
        setSelectedPillar,
        searchInput,
        setSearchInput,
        options
    } = useMainContext()

    const bgImages = pillars?.map(pillar => pillar.slug)

    if (!options) return null

    return (
        <div className="Hero">
            <div className={`outer ${selectedPillar.slug}`}>
                <div className="bgCont">
                    {bgImages.map(name => 
                        <img 
                            src={`/Images/heroBgStandard.svg`} 
                            alt="" 
                            className={`bg ${name === selectedPillar.slug ? 'active' : ''}`}
                            key={name}
                        />
                    )}
                </div>
                <h1 dangerouslySetInnerHTML={{ __html: options.heroTitle }} />
                {options.heroCopy && <div className="heroCopy" dangerouslySetInnerHTML={{ __html: options.heroCopy}} />}
                <div className="container">
                    <div className="searchBox">
                        <div className="searchBox-tabs">
                            {pillars && pillars.map((pillar, index) => {
                                const Logo = logos[index]

                                return (
                                    <div 
                                        className={`tab ${index === selectedPillar.index ? 'selected' : ''}`}
                                        onClick={() => setSelectedPillar({index, name: pillar.name, slug: pillar.slug})}
                                        key={pillar.name}
                                    >
                                        <h4 className="large" dangerouslySetInnerHTML={{ __html: pillar.name}} />
                                    </div>
                                )
                            })}
                        </div>
                            <div className="searchBox-bottom">
                                <div className='searchBox-bottom-heading'>
                                    <h4  dangerouslySetInnerHTML={{ __html: pillars?.[selectedPillar.index].description }} />
                                    <div
                                        onClick={() => setSearchInput('Showing all...')}
                                        className="searchBox-button"
                                    >
                                        { ShowAll2 }
                                        <div className="searchBox-button-logoInner">
                                            {logos[selectedPillar.slug]}
                                        </div>
                                    </div>
                                </div>
                                <div className="searchBox-bottom-inputs">
                                    <div className="searchInput">
                                        <input 
                                            type="text" 
                                            value={searchInput} onChange={e => setSearchInput(e.target.value)}
                                            placeholder="Search for benefits & programs"
                                        />
                                        { searchInput === '' ?
                                            <i className={`fe fe-search`} />
                                        :
                                            <i className={`fe fe-x`} onClick={() => setSearchInput("")}/>
                                        }
                                    </div>
                                    <Suggestions />
                                </div>
                                <div
                                    onClick={() => setSearchInput('Showing all...')}
                                    className="searchBox-button-mobile"
                                >
                                    { Star }
                                    <span
                                        dangerouslySetInnerHTML={{ __html: 'View all benefits' }}
                                    />
                                    { Arrow }
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Hero