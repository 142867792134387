import React from 'react'

import './Intro.sass'

import { useMainContext } from 'Utils'

function Intro({
    title
}) {

    const {
        isMobile
    } = useMainContext()

    return (
        <div className="container">
            <div className="Intro-title">
                <h2 dangerouslySetInnerHTML={{ __html: title}} />
            </div>
            <div className="Intro-graphicCont">
                <img src={`/Images/introHeads.svg`} alt="" />
            </div>
        </div>
    )
}

export default Intro
