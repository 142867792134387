import { Link } from "react-router-dom"

export default function HeaderMenu({
    menuItems,
    handleClick,
}) {
    const lastLink = menuItems[menuItems.length - 1]

    const scrollToId = item => {
        const { id, offset } = item

        const element = document.getElementById(id)
        const bounding = element.getBoundingClientRect()

        const pos = (bounding.top + window.pageYOffset) - offset

        window.scrollTo({
            top: pos,
            behavior: 'smooth'
        })
    }

    return (
        <>
            <ul className="headerMenu">
                {menuItems.map((item, index) => {
                    const {
                        url,
                        label
                    } = item

                    return (
                        <li
                            key={index}
                            onClick={handleClick}
                        >
                            {menuItems.length === index + 1 ?
                                null
                                :
                                (url && url.indexOf('http') !== -1) ?
                                    <a
                                        href={url || ''}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <span dangerouslySetInnerHTML={{ __html: label }} />
                                    </a>
                                :
                                    <Link
                                        to={url || ''}
                                        onClick={() => scrollToId(item)}
                                    >
                                        <span dangerouslySetInnerHTML={{ __html: label }} />
                                    </Link>
                            }
                        </li>
                    )
                })}
            </ul>
            { (lastLink.url && lastLink.url.indexOf('http') !== -1) ?
                <a 
                    href={lastLink.url}
                    className={'button'}
                    target="_blank"
                    rel="noreferrer"
                >
                    { lastLink.label }
                </a>
            :
                <a 
                    href={lastLink.url}
                    className={'button'}
                >
                    { lastLink.label }
                </a>
            }
        </>
    )
}