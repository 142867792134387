import { useMainContext } from 'Utils'

const Suggestions = () => {
    const {
        selectedPillar,
        setSearchInput,
        options,
    } = useMainContext()

    const suggestions = options ? options.suggestions.find(suggestion => suggestion.pillar[0].slug === selectedPillar.slug).categories : []

    return (
        <div className="suggested">
            <span className="copy">Suggested:</span>
            {suggestions.length ?
                <ul>
                    {suggestions.map((suggestion, index) => {
                        return (
                            <li key={index} onClick={() => setSearchInput(suggestion.name)}>
                                <span className="copy" dangerouslySetInnerHTML={{ __html: suggestion.name }} />
                            </li>
                        )
                    })}
                </ul>
                : null}
        </div>
    )
}

export default Suggestions
