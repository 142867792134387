import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import 'intersection-observer'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { createClient, Provider } from 'urql'

const client = createClient({
  url: 'https://medibank-benefits.yourcreative.com.au/graphql',
})

ReactDOM.render(
  <React.StrictMode>
    <Provider value={client}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
