export default (
    <svg id="Group_683" dataname="Group 683" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 194.599 142.395">
        <defs>
            <clipPath id="clip-path">
            <path id="Path_467" dataname="Path 467" d="M0,25.466H194.6v-142.4H0Z" transform="translate(0 116.929)" fill="#fff"/>
            </clipPath>
        </defs>
        <g id="Group_682" dataname="Group 682" clipPath="url(#clip-path)">
            <g id="Group_667" dataname="Group 667" transform="translate(0 52.735)">
            <path id="Path_452" dataname="Path 452" d="M0,.508a2.51,2.51,0,0,1,2.839-2.84H6.194A2.511,2.511,0,0,1,9.034.508V31.737a2.51,2.51,0,0,1-2.84,2.839H2.839A2.509,2.509,0,0,1,0,31.737Z" transform="translate(0 2.332)" fill="#fff"/>
            </g>
            <g id="Group_668" dataname="Group 668" transform="translate(16.349 52.735)">
            <path id="Path_453" dataname="Path 453" d="M.464.489A2.665,2.665,0,0,1,3.406-2.247H8.051a3.1,3.1,0,0,1,3.2,2.324l4.594,13.265q.309.879.645,1.936t.593,1.986q.311,1.085.62,2.169h.1q.311-1.085.62-2.169.259-.93.594-1.986t.645-1.936L24.26.077a3.1,3.1,0,0,1,3.2-2.324h4.646A2.664,2.664,0,0,1,35.047.489l2.531,31.228a2.806,2.806,0,0,1-.594,2.169,2.673,2.673,0,0,1-2.091.775H31.538A2.664,2.664,0,0,1,28.6,31.926l-.929-13.886q-.1-1.032-.13-2.142t.027-1.987a20.55,20.55,0,0,1,.1-2.064h-.1q-.362,1.136-.723,2.219-.309.93-.67,2.013T25.5,18.039l-3.046,8.415a3.178,3.178,0,0,1-3.252,2.27h-2.89a3.178,3.178,0,0,1-3.253-2.27l-3.046-8.415q-.309-.877-.67-1.962t-.671-2.013q-.364-1.083-.723-2.219h-.1q0,1.032.051,2.064,0,.879.027,1.987a17.845,17.845,0,0,1-.078,2.142L6.916,31.926a2.665,2.665,0,0,1-2.942,2.735H.568a2.674,2.674,0,0,1-2.091-.775,2.791,2.791,0,0,1-.593-2.169Z" transform="translate(2.13 2.247)" fill="#fff"/>
            </g>
            <g id="Group_669" dataname="Group 669" transform="translate(63.386 52.735)">
            <path id="Path_454" dataname="Path 454" d="M0,.508a2.51,2.51,0,0,1,2.839-2.84H15.331a12.585,12.585,0,0,1,4.9.929A10.775,10.775,0,0,1,24.028,1.23a12.268,12.268,0,0,1,2.453,4.026,14.39,14.39,0,0,1,.877,5.11,14.6,14.6,0,0,1-.877,5.137,12.206,12.206,0,0,1-2.453,4.053,11.045,11.045,0,0,1-3.795,2.657,12.248,12.248,0,0,1-4.9.955h-6.3v8.57a2.51,2.51,0,0,1-2.84,2.839H2.839A2.509,2.509,0,0,1,0,31.737ZM13.472,15.425a4.3,4.3,0,0,0,3.536-1.419,5.565,5.565,0,0,0,1.161-3.64,5.451,5.451,0,0,0-1.135-3.588,4.179,4.179,0,0,0-3.407-1.368H9.034V15.425Z" transform="translate(0 2.332)" fill="#fff"/>
            </g>
            <g id="Group_670" dataname="Group 670" transform="translate(91.597 52.735)">
            <path id="Path_455" dataname="Path 455" d="M1.952.416a3.1,3.1,0,0,1,3.2-2.324H9.8A3.1,3.1,0,0,1,13,.416L23.684,31.747a2.492,2.492,0,0,1-.129,2.375A2.545,2.545,0,0,1,21.361,35H17.954a3.107,3.107,0,0,1-3.2-2.375l-1.548-5.368H1.7L.146,32.625A2.98,2.98,0,0,1-3,35H-6.411A2.543,2.543,0,0,1-8.6,34.122a2.486,2.486,0,0,1-.129-2.375Zm9.137,19.615L9.024,12.855q-.259-.877-.49-1.962T8.042,8.829q-.258-1.134-.515-2.322h-.1q-.259,1.187-.516,2.322-.259.981-.49,2.064t-.491,1.962L3.81,20.031Z" transform="translate(8.963 1.908)" fill="#fff"/>
            </g>
            <g id="Group_671" dataname="Group 671" transform="translate(127.134 52.116)">
            <path id="Path_456" dataname="Path 456" d="M0,3.4A19.5,19.5,0,0,1,1.42-4.087,18.15,18.15,0,0,1,5.394-10.1a18.568,18.568,0,0,1,6.117-4.027,20.367,20.367,0,0,1,7.846-1.47,21.475,21.475,0,0,1,3.923.335,23.24,23.24,0,0,1,3.3.852,17.181,17.181,0,0,1,2.632,1.135,22.692,22.692,0,0,1,1.962,1.187,2.62,2.62,0,0,1,1.213,1.782A3.225,3.225,0,0,1,31.8-8.165l-1.29,2.117a2.529,2.529,0,0,1-1.7,1.341,3.221,3.221,0,0,1-2.168-.567A23.5,23.5,0,0,0,23.59-6.669a10.654,10.654,0,0,0-3.82-.67,11.311,11.311,0,0,0-4.749.9,9.338,9.338,0,0,0-3.226,2.375A9.441,9.441,0,0,0,9.936-.707a12.894,12.894,0,0,0-.593,3.9,13.544,13.544,0,0,0,.619,4.052A10.472,10.472,0,0,0,11.872,10.8a9.5,9.5,0,0,0,3.252,2.528,10.51,10.51,0,0,0,4.646.956,10.444,10.444,0,0,0,4.259-.852,19.744,19.744,0,0,0,3.277-1.78q2.426-1.7,3.975.516l1.446,2.013a3.351,3.351,0,0,1,.645,2.117,2.636,2.636,0,0,1-1.111,1.858q-.877.67-2.115,1.446a17.9,17.9,0,0,1-2.865,1.419,23.456,23.456,0,0,1-3.613,1.084,19.994,19.994,0,0,1-4.31.438A21.329,21.329,0,0,1,11.33,21.1a17.667,17.667,0,0,1-6.117-4.027,17.569,17.569,0,0,1-3.871-6.065A21,21,0,0,1,0,3.4" transform="translate(0 15.598)" fill="#fff"/>
            </g>
            <g id="Group_672" dataname="Group 672" transform="translate(163.163 52.735)">
            <path id="Path_457" dataname="Path 457" d="M2,1.385H-6.358A2.509,2.509,0,0,1-9.2-1.454V-3.518a2.511,2.511,0,0,1,2.84-2.84H19.4a2.51,2.51,0,0,1,2.839,2.84v2.064A2.509,2.509,0,0,1,19.4,1.385H11.037V27.711A2.51,2.51,0,0,1,8.2,30.55H4.843A2.509,2.509,0,0,1,2,27.711Z" transform="translate(9.198 6.358)" fill="#fff"/>
            </g>
            <g id="Group_673" dataname="Group 673" transform="translate(16.004 120.542)">
            <path id="Path_458" dataname="Path 458" d="M0,.294A1.451,1.451,0,0,1,1.642-1.348H7.85a11.338,11.338,0,0,1,2.94.358A6.837,6.837,0,0,1,13.073.07a4.951,4.951,0,0,1,1.492,1.746A5.208,5.208,0,0,1,15.1,4.234a5.74,5.74,0,0,1-.627,2.6,4.475,4.475,0,0,1-1.85,1.94v.06a4.251,4.251,0,0,1,1.551.79,4.829,4.829,0,0,1,1.612,2.568,6.063,6.063,0,0,1,.179,1.447,6.035,6.035,0,0,1-.672,2.97,5.529,5.529,0,0,1-1.79,1.955,7.824,7.824,0,0,1-2.6,1.09,13.469,13.469,0,0,1-3.059.343H1.642A1.451,1.451,0,0,1,0,18.352ZM7.91,6.921a1.671,1.671,0,0,0,1.432-.6A2.219,2.219,0,0,0,9.79,4.95a1.9,1.9,0,0,0-.463-1.284A1.891,1.891,0,0,0,7.819,3.13h-2.6V6.921Zm.448,8.6a2.1,2.1,0,0,0,1.7-.671,2.355,2.355,0,0,0,.569-1.567,2.282,2.282,0,0,0-.553-1.537A2.106,2.106,0,0,0,8.387,11.1H5.223v4.417Z" transform="translate(0 1.348)" fill="#fff"/>
            </g>
            <g id="Group_674" dataname="Group 674" transform="translate(35.968 117.493)">
            <path id="Path_459" dataname="Path 459" d="M0,.336A1.659,1.659,0,0,1,1.877-1.541H4.093A1.659,1.659,0,0,1,5.97.336V20.974a1.658,1.658,0,0,1-1.877,1.875H1.877A1.658,1.658,0,0,1,0,20.974Z" transform="translate(0 1.541)" fill="#fff"/>
            </g>
            <g id="Group_675" dataname="Group 675" transform="translate(46.371 113.985)">
            <path id="Path_460" dataname="Path 460" d="M0,2.567A15.134,15.134,0,0,1,1.074-3.209,13.443,13.443,0,0,1,4.067-7.737a13.783,13.783,0,0,1,4.567-2.975,15.3,15.3,0,0,1,5.8-1.073,16.853,16.853,0,0,1,3.09.268,22.2,22.2,0,0,1,2.59.633,12.91,12.91,0,0,1,1.975.787,9.941,9.941,0,0,1,1.248.728,2.15,2.15,0,0,1,.978,1.344,2.44,2.44,0,0,1-.441,1.573l-1,1.573a1.8,1.8,0,0,1-1.247.978,2.86,2.86,0,0,1-1.668-.4,17.525,17.525,0,0,0-2.131-.921,8.879,8.879,0,0,0-2.974-.423,8.98,8.98,0,0,0-3.55.633,6.8,6.8,0,0,0-3.9,4.24,9.567,9.567,0,0,0-.459,2.956A8.831,8.831,0,0,0,7.58,5.617a8.084,8.084,0,0,0,1.688,2.59,7.259,7.259,0,0,0,2.476,1.651,7.913,7.913,0,0,0,2.993.575,8.311,8.311,0,0,0,2.533-.345,8.285,8.285,0,0,0,1.765-.768,4.8,4.8,0,0,0,1.343-1.113V6.327H18.613A1.866,1.866,0,0,1,16.5,4.217V2.72A1.866,1.866,0,0,1,18.613.61h5.6a1.865,1.865,0,0,1,2.11,2.11V14a1.865,1.865,0,0,1-2.11,2.11H22.948a2.29,2.29,0,0,1-1.573-.48,1.512,1.512,0,0,1-.537-1.17v-.346A.157.157,0,0,1,20.877,14H20.8A7.672,7.672,0,0,1,19,15.308a11.219,11.219,0,0,1-2.285.882,11.886,11.886,0,0,1-3.2.384A13.514,13.514,0,0,1,8.423,15.6a13.39,13.39,0,0,1-4.317-2.783A13.191,13.191,0,0,1,1.113,8.4,14.713,14.713,0,0,1,0,2.567" transform="translate(0 11.785)" fill="#fff"/>
            </g>
            <g id="Group_676" dataname="Group 676" transform="translate(76.593 110.884)">
            <path id="Path_461" dataname="Path 461" d="M0,2.852A16.81,16.81,0,0,1,1.193-3.564,14.97,14.97,0,0,1,4.519-8.6a15.318,15.318,0,0,1,5.075-3.3A16.958,16.958,0,0,1,16.032-13.1a18.64,18.64,0,0,1,3.433.3,24.21,24.21,0,0,1,2.878.7,14.491,14.491,0,0,1,2.2.874,11.14,11.14,0,0,1,1.387.81,2.4,2.4,0,0,1,1.087,1.493,2.715,2.715,0,0,1-.491,1.748L25.413-5.42a2.007,2.007,0,0,1-1.385,1.087,3.173,3.173,0,0,1-1.855-.448A19.573,19.573,0,0,0,19.806-5.8,9.865,9.865,0,0,0,16.5-6.273a9.982,9.982,0,0,0-3.943.7A7.545,7.545,0,0,0,8.23-.858a10.554,10.554,0,0,0-.511,3.283,9.818,9.818,0,0,0,.7,3.817A8.987,8.987,0,0,0,10.3,9.12a8.01,8.01,0,0,0,2.751,1.833,8.763,8.763,0,0,0,3.326.639,9.072,9.072,0,0,0,4.775-1.236A5.323,5.323,0,0,0,22.642,9.12V7.03H20.681a2.073,2.073,0,0,1-2.345-2.345V3.023A2.073,2.073,0,0,1,20.681.677h6.225a2.073,2.073,0,0,1,2.345,2.345V15.559A2.073,2.073,0,0,1,26.906,17.9H25.5a2.552,2.552,0,0,1-1.749-.532,1.683,1.683,0,0,1-.6-1.3v-.384a.171.171,0,0,1,.043-.128h-.085a8.533,8.533,0,0,1-2,1.45,12.47,12.47,0,0,1-2.537.98,13.234,13.234,0,0,1-3.561.426A15.028,15.028,0,0,1,9.36,17.328a14.839,14.839,0,0,1-4.8-3.092,14.642,14.642,0,0,1-3.326-4.9A16.339,16.339,0,0,1,0,2.852" transform="translate(0 13.096)" fill="#fff"/>
            </g>
            <g id="Group_677" dataname="Group 677" transform="translate(111.894 108.346)">
            <path id="Path_462" dataname="Path 462" d="M0,.462A2.281,2.281,0,0,1,2.581-2.119H18.434A2.281,2.281,0,0,1,21.013.462V2.338a2.28,2.28,0,0,1-2.579,2.579H8.208v6.1h7.645a2.281,2.281,0,0,1,2.581,2.579v1.877a2.28,2.28,0,0,1-2.581,2.579H8.208v6.333H19.09a2.281,2.281,0,0,1,2.579,2.581v1.875a2.28,2.28,0,0,1-2.579,2.579H2.581A2.28,2.28,0,0,1,0,28.839Z" transform="translate(0 2.119)" fill="#fff"/>
            </g>
            <g id="Group_678" dataname="Group 678" transform="translate(139.658 104.976)">
            <path id="Path_463" dataname="Path 463" d="M0,.508a2.51,2.51,0,0,1,2.839-2.84H12.853a36.316,36.316,0,0,1,4.413.207,11.828,11.828,0,0,1,2.762.671A10.106,10.106,0,0,1,25.009,2.5a12.007,12.007,0,0,1,1.833,6.787,13.345,13.345,0,0,1-.335,2.968,12.318,12.318,0,0,1-1.033,2.84,10.85,10.85,0,0,1-1.729,2.477,8.048,8.048,0,0,1-2.426,1.782v.1c.1.138.223.309.362.515a4.719,4.719,0,0,1,.438.7q.232.44.593,1.008l5.265,9.5a2.317,2.317,0,0,1,.285,2.453,2.434,2.434,0,0,1-2.246.955H22.093a3.636,3.636,0,0,1-3.356-2.013L13.265,22.136H9.034v9.6a2.51,2.51,0,0,1-2.84,2.839H2.839A2.509,2.509,0,0,1,0,31.737ZM12.956,14.393a4.886,4.886,0,0,0,3.433-1.162,4.362,4.362,0,0,0,1.264-3.381,6,6,0,0,0-.438-2.4,2.95,2.95,0,0,0-1.729-1.522,8.838,8.838,0,0,0-3.406-.518H9.034v8.982Z" transform="translate(0 2.332)" fill="#fff"/>
            </g>
            <g id="Group_679" dataname="Group 679" transform="translate(138.799 17.301)">
            <path id="Path_464" dataname="Path 464" d="M.9,3.3A5.039,5.039,0,0,1-2.671,1.822a5.055,5.055,0,0,1,0-7.151l8.353-8.354a5.061,5.061,0,0,1,7.152,0,5.059,5.059,0,0,1,0,7.152L4.48,1.822A5.039,5.039,0,0,1,.9,3.3" transform="translate(4.152 15.163)" fill="#fff"/>
            </g>
            <g id="Group_680" dataname="Group 680" transform="translate(25.813 17.301)">
            <path id="Path_465" dataname="Path 465" d="M2.4,3.3A5.039,5.039,0,0,1-1.177,1.822L-9.531-6.531a5.059,5.059,0,0,1,0-7.152,5.061,5.061,0,0,1,7.152,0L5.974-5.329a5.055,5.055,0,0,1,0,7.151A5.039,5.039,0,0,1,2.4,3.3" transform="translate(11.012 15.163)" fill="#fff"/>
            </g>
            <g id="Group_681" dataname="Group 681" transform="translate(86.483 0)">
            <path id="Path_466" dataname="Path 466" d="M.9,4.9A5.056,5.056,0,0,1-4.152-.154v-17.3A5.057,5.057,0,0,1,.9-22.511a5.057,5.057,0,0,1,5.056,5.057v17.3A5.056,5.056,0,0,1,.9,4.9" transform="translate(4.152 22.511)" fill="#fff"/>
            </g>
        </g>
    </svg>
)