import React, { useState, useEffect, useRef } from 'react'
import { useMainContext } from 'Utils'

import './VideoSlider.sass'

import Slider from 'Components/Slider/Slider'
import Slide from './Slide'

function VideoSlider({
    title,
    videos
}) {
    const {isMobile} = useMainContext()

    const flickityRef = useRef(null)

    const [currentSlide, setCurrentSlide] = useState(0)

    const nextSlide = () => {
        if (flickityRef.current) {
            flickityRef.current.next(false)
            setCurrentSlide(flickityRef.current.selectedIndex)
        }
    }

    const previousSlide = () => {
        if (flickityRef.current) {
            flickityRef.current.previous(false)
            setCurrentSlide(flickityRef.current.selectedIndex)
        }
    }

    useEffect(() => {
        if (flickityRef.current) {
            flickityRef.current.on('change', index => {
                setCurrentSlide(index)
            })
        }
    }, [flickityRef.current])

    const _videos = isMobile ? videos : videos.length <= 6 ? videos.concat(videos).concat(videos) : videos

    return (
        <div className="VideoSlider-outer">
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            <div className="VideoSlider-sliderCont">
                <div className="VideoSlider-controls">
                    <div>
                        <div
                            className="control" 
                            onClick={previousSlide}
                        >
                            <i className="fe fe-arrow-left" />
                        </div>
                        <div 
                            className="control" 
                            onClick={nextSlide}
                        >
                            <i className="fe fe-arrow-right" />
                        </div>
                    </div>
                </div>
                <Slider
                    options={{
                        prevNextButtons: false,
                        pageDots: isMobile,
                        wrapAround: true,
                        cellAlign: 'center',
                    }}
                    className="Slider"
                    flickityRef={ref => flickityRef.current = ref}
                >
                    {_videos && _videos.map((slide, index) => {
                        return (
                            <Slide 
                                slide={slide}
                                isActive={index === currentSlide}
                                key={index}
                            />
                        )
                    })}
                </Slider>
            </div>
        </div>
    )
}

export default VideoSlider
