import React, { useState } from 'react'

import HeaderMenu from './HeaderMenu'

import './Header.sass'

import Logo from 'Images/logo'

function Header({
    menu,
}) {

    const [menuOpen, setMenuOpen] = useState(false)

    const handleClick = () => {
        setMenuOpen(false)
    }

    const handleMenuToggle = () => {
        menuOpen ? setMenuOpen(false) : setMenuOpen(true)
    }

    return (
        <header className={`header ${menuOpen ? 'open' : ''}`}>
            <div className="container">
                {Logo}
                <HeaderMenu menuItems={menu} handleClick={handleClick} />
                <div className="toggle" onClick={() => handleMenuToggle()}>
                    <span />
                </div>
            </div>
        </header>
    )
}

export default Header
