import Button from 'Components/Button/Button'

function ViewButtons({
    handleViewSearchResults,
    viewType,
}) {
    return (
        <div className={`viewButtons`}>
            <span
                dangerouslySetInnerHTML={{ __html: 'View:' }}
            />
            <Button
                label={'All'}
                onClick={() => handleViewSearchResults('')}
                className={`${viewType === '' ? 'active' : ''}`}
            />
            <Button
                label={'New'}
                onClick={() => handleViewSearchResults('new')}
                className={`${viewType === 'new' ? 'active' : ''}`}
            />
            <Button
                label={'Updated'}
                onClick={() => handleViewSearchResults('updated')}
                className={`${viewType === 'updated' ? 'active' : ''}`}
            />
        </div>
    )
}

export default ViewButtons
