import { useEffect } from 'react'
import Footer from 'Components/Footer/Footer'
import Main from 'Pages/Main/Main'
import Modal from 'Components/Modal/Modal'
import { ContextProvider } from 'Utils/context'

import {
    Routes,
    Route,
    useLocation
} from "react-router-dom"

import { pageview } from 'Utils/analytics'

const AppInner = ({
    contextData,
}) => {
    const location = useLocation()

    useEffect(() => {
        setTimeout(() => {
            pageview()
        },500)
    }, [location])

    return (
        <ContextProvider initialContext={contextData}>
            <Routes>
                <Route path="/" element={<Main />} />
            </Routes>
            <Footer />
            <Modal />
        </ContextProvider>
    )
}

export default AppInner