import './Button.sass'
import { Link } from 'react-router-dom'

const Button = ({
    label,
    onClick,
    className,
    href,
    style,
}) => {
    return (
        <button 
            className={`button${ className ? ' ' + className : '' }`}
            onClick={onClick}
            style={style}
        >
            { href ?
                (href && href.indexOf('http') !== -1) ?
                    <a href={href} target="_blank" rel="noreferrer">{label}</a>
                :
                    <a href={href}>{label}</a>
            : label }
        </button>
    )
}

export default Button
