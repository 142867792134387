import React, { useEffect } from "react"
import {
  BrowserRouter,
} from "react-router-dom"

import 'Styles/global.sass'

import { initGA } from 'Utils/analytics'
import AppInner from "AppInner"

function App() {
  useEffect(() => {
    initGA()
  }, [])

  return (
    <BrowserRouter>
      <AppInner />
    </BrowserRouter>
  )
}

export default App
