import React from "react"

import Logo from 'Images/logo'

import './Footer.sass'

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                { Logo }
                <div dangerouslySetInnerHTML={{ __html: 'Copyright &copy; 2021 Medibank Private Limited. All rights reserved. ABN 47 080 890 259.' }} />
            </div>
        </footer>
    )
}

export default Footer
