import React, { useEffect } from "react"

import './Main.sass'

import Header from "Components/Header/Header"
import Hero from "Components//Hero/Hero"
import FlexibleContentRow from "./FlexibleContentRow"
import QuizCta from "Components/QuizCta/QuizCta"
import SearchResults from "Components/SearchResults/SearchResults"
import ImageCta from "Components/FlexibleContent/ImageCta/ImageCta"
import FeelGoodSection from "Components/FlexibleContent/FeelGoodSection/FeelGoodSection"

import { useMainContext } from "Utils"

import TailSpin from 'Images/tailSpin'

import { useQuery } from "urql"

// const CHECK_AUTHENTICATION = `
//     mutation (
//         $token: String!
//     ) {
//         checkAuthentication (
//             input: {
//                 token: $token
//                 clientMutationId: "uniqueId"
//             }
//         ) {
//             success
//         }
//     }
// `

const FLEXIBLE_CONTENT = `
    query {
        page (
            id: 2
            idType: DATABASE_ID
        ) {
            components {
                rows {
                    ...on Page_Components_Rows_BenefitSlider {
                        title
                    }
                    ...on Page_Components_Rows_Intro {
                        title
                    }
                    ...on Page_Components_Rows_VideoSlider {
                        title
                        videos {
                            title
                            copy
                            link {
                                title
                                url
                            }
                            image {
                                mediaItemUrl
                            }
                            benefitPillar {
                                ... on BenefitPillar {
                                    slug
                                }
                            }
                        }
                    }
                    ...on Page_Components_Rows_ImageCta {
                        title
                        copy
                        image {
                            mediaItemUrl
                        }
                        button {
                            url
                            title
                        }
                        orientation
                    }
                    ...on Page_Components_Rows_FeelGoodSection {
                        title
                        copy
                        optionsTitle
                        options {
                            title
                            url
                            icon {
                                mediaItemUrl
                            }
                            backgroundColour
                        }
                    }
                }
            }
        }
        menus(where: {id: 40}) {
            nodes {
                menuItems {
                    nodes {
                        label
                        url
                    }
                }
            }
        }
    }
`

// Auto-import all components in FlexibleContent
const importContext = require.context('Components/FlexibleContent', true, /.jsx$/)
const allComponents = {}

importContext.keys().forEach(key => {
    const fileSegments = key.split('/')
    const fileName = fileSegments[fileSegments.length - 1]

    const resource = require(`Components/FlexibleContent/${key.replace('./','')}`).default
    const namespace = fileName.replace('.jsx', '')
    allComponents[namespace] = resource
})

function Main() {
    const [{
        data,
        fetching
    }] = useQuery({
        query: FLEXIBLE_CONTENT
    })

    useEffect(() => {
        document.title = `Medibank Benefits`
    }, [])

    const {
        searchInput,
        setSearchInput,
        selectedPillar,
    } = useMainContext()

    useEffect(() => {
        if ( searchInput !== 'Showing all...') {
            setSearchInput('')
        }
    }, [selectedPillar])

    if (fetching || !data) {
        return (
            <div className="mainloading">
                { TailSpin }
            </div>
        )
    }

    const {
        rows
    } = data.page.components

    const menu = data.menus ? data.menus.nodes[0].menuItems.nodes : null

    return (
        <>
            <Header menu={ menu } />
            <Hero />
            <SearchResults />
            <div className={ `flexible-content${ searchInput ? ' searchResultsVisible' : '' }` }>
                { rows.map((row, index) => {
                    const key = row.__typename.replace('Page_Components_Rows_', '')

                    const Component = allComponents[key]

                    if(!Component){
                        console.log(`component missing for ${ key }`)

                        return null 
                    }

                    return (
                        <FlexibleContentRow 
                            Component={Component}
                            row={row}
                            className={key}
                            key={index}
                        />
                    )
                })}
            </div>
            <QuizCta hide="true" />
        </>
    )
}

export default Main
