import React from 'react'

import './CategoryTag.sass'


function CategoryTag({ category }) {
    const {
        name,
        categoryFields
    } = category

    return (
        <span 
            className={`categoryTag ${categoryFields.colourScheme ? categoryFields.colourScheme : ''}`} 
            dangerouslySetInnerHTML={{ __html: name }} 
        /> 
    )
}

export default CategoryTag
