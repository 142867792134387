import { useMainContext } from "Utils"
import { getNew, getUpdated } from "Utils"


export default function Tag({created, modified}) {
    const {
        options: {
            newTagColour,
            updatedTagColour
        }
    } = useMainContext()

    const isNew = getNew(created)
    const isUpdated = getUpdated(created, modified)

    if (!isNew && !isUpdated) return null

    return (
        <span
            className={`newOrUpdatedTag ${isNew ? 'new' : isUpdated ? 'updated' : 'none'}`}
            dangerouslySetInnerHTML={{ __html: isNew ? 'New!' : 'Updated!' }}
            style={{ 
                backgroundColor: isNew ? newTagColour : updatedTagColour
            }}
        />
    )
}