import React, { useContext } from 'react'
import { MainContext } from "Utils/context"

import Benefit from './Benefit'

import './Modal.sass'

function Modal() {
    const {
        modalData,
        closeModal,
    } = useContext(MainContext)

    const {
        type,
        data,
        isVisible,
    } = modalData

    let component = null

    const commonProps = {
        data,
        isVisible,
        close: closeModal
    }

    switch (type) {
        case 'BENEFIT':
            component = (
                <Benefit
                    {...commonProps}
                />
            )
            break
        default:
            break
    }

    return (
        <div
            className={`Modal ${isVisible ? 'visible' : ''} ${type}`}
            onClick={closeModal}
            onKeyDown={closeModal}
            role="button"
            tabIndex={0}
        >
            <div
                className={type}
                onClick={e => e.stopPropagation()}
                onKeyDown={e => e.stopPropagation()}
                role="button"
                tabIndex={0}
            >
                {component}
            </div>
        </div>
    )
}

export default Modal
