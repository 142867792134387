export default (
    <svg id="Group_886" dataname="Group 886" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 177.363 168.077">
        <g id="Group_697" dataname="Group 697" transform="translate(0 0)">
            <g id="Group_684" dataname="Group 684" transform="translate(-0.001 69.04)">
            <path id="Path_468" dataname="Path 468" d="M.152,4.284A3.724,3.724,0,0,1-.515,3.64a2.163,2.163,0,0,1-.406-.811,2.025,2.025,0,0,1,0-.977A3.444,3.444,0,0,1-.324.658L1.154-1.489A2.4,2.4,0,0,1,2.73-2.658a3.39,3.39,0,0,1,2.051.549l1.025.573q.6.333,1.385.691a10.081,10.081,0,0,0,1.741.6,8.341,8.341,0,0,0,2.051.238A4.428,4.428,0,0,0,13.725-.7a2.079,2.079,0,0,0,.882-1.693,2.463,2.463,0,0,0-1.118-2.028A14.612,14.612,0,0,0,10.7-6Q9.027-6.737,7.07-7.572A17.661,17.661,0,0,1,3.445-9.647,11.029,11.029,0,0,1,.653-12.676,8.263,8.263,0,0,1-.468-17.136a9.7,9.7,0,0,1,.931-4.27,10.02,10.02,0,0,1,2.552-3.316A11.373,11.373,0,0,1,6.88-26.845a15.277,15.277,0,0,1,4.818-.739,17.145,17.145,0,0,1,5.487.834,15.4,15.4,0,0,1,3.72,1.694A2.642,2.642,0,0,1,22.1-23.41a3.178,3.178,0,0,1-.382,1.932l-1.144,2.242c-.478.922-1.025,1.391-1.645,1.407a3.921,3.921,0,0,1-1.933-.5Q15.8-18.9,14.536-19.4a7.929,7.929,0,0,0-2.935-.5,4.61,4.61,0,0,0-2.862.693,2.1,2.1,0,0,0-.858,1.693A2.443,2.443,0,0,0,9-15.467a14.672,14.672,0,0,0,2.79,1.527q1.669.714,3.625,1.5a16.8,16.8,0,0,1,3.628,2,10.722,10.722,0,0,1,2.79,3.006,8.253,8.253,0,0,1,1.121,4.461,10.681,10.681,0,0,1-.787,4.078A9.671,9.671,0,0,1,19.856,4.5a11.207,11.207,0,0,1-3.77,2.314,14.212,14.212,0,0,1-5.1.858,17.364,17.364,0,0,1-3.46-.334A22.386,22.386,0,0,1,4.471,6.5,18.583,18.583,0,0,1,1.943,5.4,12.037,12.037,0,0,1,.152,4.284" transform="translate(0.981 27.584)" fill="#fff"/>
            </g>
            <g id="Group_685" dataname="Group 685" transform="translate(26.989 69.612)">
            <path id="Path_469" dataname="Path 469" d="M1.392.962H-6.337A2.319,2.319,0,0,1-8.961-1.663V-3.57A2.319,2.319,0,0,1-6.337-6.194H17.469A2.32,2.32,0,0,1,20.093-3.57v1.907A2.32,2.32,0,0,1,17.469.962H9.74v24.33a2.319,2.319,0,0,1-2.624,2.624h-3.1a2.318,2.318,0,0,1-2.624-2.624Z" transform="translate(8.961 6.194)" fill="#fff"/>
            </g>
            <g id="Group_686" dataname="Group 686" transform="translate(60.813 69.612)">
            <path id="Path_470" dataname="Path 470" d="M0,.353A2.319,2.319,0,0,1,2.624-2.271H18.747A2.32,2.32,0,0,1,21.371.353V2.26a2.319,2.319,0,0,1-2.624,2.625H8.347v6.2h7.776a2.32,2.32,0,0,1,2.624,2.624v1.909a2.319,2.319,0,0,1-2.624,2.624H8.347v6.44H19.416a2.32,2.32,0,0,1,2.624,2.625v1.909a2.318,2.318,0,0,1-2.624,2.623H2.624A2.318,2.318,0,0,1,0,29.216Z" transform="translate(0 2.271)" fill="#fff"/>
            </g>
            <g id="Group_687" dataname="Group 687" transform="translate(88.721 69.612)">
            <path id="Path_471" dataname="Path 471" d="M0,.353A2.319,2.319,0,0,1,2.624-2.271H14.169a11.639,11.639,0,0,1,4.532.858,9.939,9.939,0,0,1,3.505,2.434,11.286,11.286,0,0,1,2.267,3.719,13.275,13.275,0,0,1,.811,4.724,13.468,13.468,0,0,1-.811,4.747,11.239,11.239,0,0,1-2.267,3.744A10.194,10.194,0,0,1,18.7,20.414a11.36,11.36,0,0,1-4.532.883H8.348v7.92a2.319,2.319,0,0,1-2.624,2.623h-3.1A2.318,2.318,0,0,1,0,29.216ZM12.452,14.14a3.971,3.971,0,0,0,3.266-1.311,5.135,5.135,0,0,0,1.074-3.364,5.017,5.017,0,0,0-1.05-3.315,3.851,3.851,0,0,0-3.148-1.265H8.348V14.14Z" transform="translate(0 2.271)" fill="#fff"/>
            </g>
            <g id="Group_688" dataname="Group 688" transform="translate(132.42 69.612)">
            <path id="Path_472" dataname="Path 472" d="M0,.353A2.319,2.319,0,0,1,2.624-2.271h3.1A2.32,2.32,0,0,1,8.347.353V29.216a2.318,2.318,0,0,1-2.624,2.623h-3.1A2.318,2.318,0,0,1,0,29.216Z" transform="translate(0 2.271)" fill="#fff"/>
            </g>
            <g id="Group_689" dataname="Group 689" transform="translate(148.544 69.612)">
            <path id="Path_473" dataname="Path 473" d="M0,.353A2.32,2.32,0,0,1,2.624-2.271H6.345A3.364,3.364,0,0,1,9.493-.506l8.4,14.168a20.3,20.3,0,0,1,1,1.861q.478,1,.908,1.909.475,1.048.953,2.052h.095q-.1-1.05-.191-2.1c-.064-.6-.111-1.241-.143-1.909s-.047-1.272-.047-1.814V.353A2.319,2.319,0,0,1,23.09-2.271h3.1A2.32,2.32,0,0,1,28.815.353V29.216a2.319,2.319,0,0,1-2.624,2.623H22.47a3.321,3.321,0,0,1-3.1-1.764L10.925,15.953a20.4,20.4,0,0,1-1.048-1.837q-.529-1.026-.907-1.933a21.565,21.565,0,0,1-.906-2.051h-.1q.1,1.052.192,2.1c.062.6.111,1.241.142,1.909s.049,1.272.049,1.813V29.216a2.318,2.318,0,0,1-2.624,2.623h-3.1A2.319,2.319,0,0,1,0,29.216Z" transform="translate(0 2.271)" fill="#fff"/>
            </g>
            <g id="Group_690" dataname="Group 690" transform="translate(4.756 114.957)">
            <path id="Path_474" dataname="Path 474" d="M.787,3.242a2.758,2.758,0,0,1,2.2.86v1.02c0,7.305,3.33,10.526,6.928,10.526,2.041,0,3.652-1.879,3.652-4.618,0-1.773-.322-3.223-5.317-9.56C5.406-2.182,4.493-5.191,4.493-8.52c0-5.963,4.619-12.354,11.387-12.354,5.8,0,8.109,3.331,8.109,6.553a4.993,4.993,0,0,1-2.309,4.4c-2.847-2.364-4.834-3.437-6.821-3.437a2.314,2.314,0,0,0-2.148,2.579c0,1.719.912,3.813,4.027,8.163C21.25,3.779,22,6.036,22,9.2c0,6.069-5.586,13.32-14.609,13.32-8.271,0-12.46-4.887-12.46-10.794,0-3.922,2.095-8.487,5.854-8.487" transform="translate(5.067 20.874)" fill="#fff"/>
            </g>
            <g id="Group_691" dataname="Group 691" transform="translate(36.28 119.738)">
            <path id="Path_475" dataname="Path 475" d="M1.538,1.588a37.718,37.718,0,0,0,4.619-.375,2.971,2.971,0,0,1,1.02,2.256,3.316,3.316,0,0,1-3.115,3.6A21.4,21.4,0,0,1,.141,6.745a60.433,60.433,0,0,0-1.88,9.722c0,1.288.322,1.88,1.451,1.88,2.2,0,5.907-2.579,9.56-7.734a2.068,2.068,0,0,1,2.148,1.825c0,3.169-8.218,12.782-14.394,12.782-4.135,0-6.928-2.148-6.928-7.089,0-1.557.537-4.835,2.579-11.656-1.074-.107-1.988-.215-1.988-1.4A3.2,3.2,0,0,1-5.928,1.858C-3.243-5.178-.395-10.227,3.2-10.227c1.182,0,2.095.537,2.095,1.772C5.3-6.79,3.365-4.1,1.538,1.588" transform="translate(9.902 10.227)" fill="#fff"/>
            </g>
            <g id="Group_692" dataname="Group 692" transform="translate(54.541 129.299)">
            <path id="Path_476" dataname="Path 476" d="M0,2.2c0-8.11,6.606-16.381,11.815-16.381,3.706,0,5.748,1.772,5.748,5.532,0,4.512-2.633,8-9.991,9.668,0,2.417.484,3.761,2.579,3.761,3.653,0,8.378-3.116,11.44-7.788a1.93,1.93,0,0,1,2.041,1.772c0,3.974-8.97,12.514-15.953,12.514C2.417,11.28,0,8,0,2.2M10.473-8.54c-.752,0-2.2,1.825-2.579,5.586,3.007-.537,3.653-2.2,3.653-3.814,0-1.128-.431-1.772-1.074-1.772" transform="translate(0 14.179)" fill="#fff"/>
            </g>
            <g id="Group_693" dataname="Group 693" transform="translate(73.658 126.238)">
            <path id="Path_477" dataname="Path 477" d="M0,4.982C0,3.479.806.74,2.792-2.859L3.33-18.435c.322-9.185,5.21-13.641,9.023-13.641,2.2,0,3.6,1.02,3.6,2.576a2.386,2.386,0,0,1-.054.592c-1.986,1.772-3.758,4.4-4.887,8.916,3.438-5.1,6.875-7.681,9.13-7.681,2.632,0,4.243,1.987,4.243,4.834,0,3.33-2.792,8.486-6.338,12.623a19.964,19.964,0,0,0,7.681-2.311A2.734,2.734,0,0,1,27.338-10c0,3.168-4.512,6.553-10.367,6.553-3.383,0-5.747-.752-6.606-2.149C9.615,4.122,8.272,9.763,5.747,9.763S0,7.668,0,4.982M10.58-9.465c1.021-.107,1.987-.215,2.685-.322.968-1.88,2.471-5.747,2.471-6.928,0-1.128-.375-1.5-1.073-1.5s-1.719.752-3.761,2.953q-.161,3.06-.322,5.8" transform="translate(0 32.076)" fill="#fff"/>
            </g>
            <g id="Group_694" dataname="Group 694" transform="translate(116.836 114.206)">
            <path id="Path_478" dataname="Path 478" d="M1.495,0C6.6,0,8.96,2.847,8.96,6.606c0,3.384-.752,6.822-3.867,14.824-2.041,5.21-2.632,7.841-2.632,9.453,0,2.632,1.129,3.868,3.384,3.868,3.705,0,7.949-6.5,10.742-18.584a32.075,32.075,0,0,1-.806-6.821c0-4.135,1.182-7.09,3.974-7.09,3.008,0,6.176,2.955,6.176,5,0,1.88-.375,3.491-2.2,8.11C19.648,34.267,11.968,41.947,3.59,41.947c-6.5,0-10.474-3.438-10.474-9.345A28.555,28.555,0,0,1-4.789,23.31C-1.19,14.126-.278,10.85-.278,9.13c0-1.342-.645-1.665-1.5-1.665-1.074,0-3.062,1.234-4.994,3.062A3.853,3.853,0,0,1-9.623,6.821C-9.623,2.954-4.843,0,1.495,0" transform="translate(9.623 0)" fill="#fff"/>
            </g>
            <g id="Group_695" dataname="Group 695" transform="translate(150.025 126.238)">
            <path id="Path_479" dataname="Path 479" d="M0,4.982C0,3.479.805.74,2.792-2.859L3.33-18.435c.322-9.185,5.21-13.641,9.023-13.641,2.2,0,3.6,1.02,3.6,2.576a2.386,2.386,0,0,1-.054.592c-1.986,1.772-3.758,4.4-4.887,8.916,3.437-5.1,6.875-7.681,9.13-7.681,2.632,0,4.243,1.987,4.243,4.834,0,3.33-2.792,8.486-6.338,12.623a19.964,19.964,0,0,0,7.681-2.311A2.736,2.736,0,0,1,27.338-10c0,3.168-4.513,6.553-10.367,6.553-3.384,0-5.747-.752-6.606-2.149C9.613,4.122,8.271,9.763,5.747,9.763S0,7.668,0,4.982M10.58-9.465c1.021-.107,1.987-.215,2.685-.322.967-1.88,2.471-5.747,2.471-6.928,0-1.128-.375-1.5-1.074-1.5s-1.719.752-3.759,2.953q-.161,3.06-.322,5.8" transform="translate(0 32.076)" fill="#fff"/>
            </g>
            <g id="Group_696" dataname="Group 696" transform="translate(28.783 0)">
            <path id="Path_480" dataname="Path 480" d="M15.632,6.352a5.05,5.05,0,0,1-2.8-.849L-40.1-29.749-92.781,5.262A5.063,5.063,0,0,1-99.8,3.846a5.063,5.063,0,0,1,1.415-7.02L-42.9-40.048a5.064,5.064,0,0,1,5.61,0L18.444-2.928a5.064,5.064,0,0,1-2.812,9.28" transform="translate(100.648 40.895)" fill="#fff"/>
            </g>
        </g>
    </svg>
)