import Button from 'Components/Button/Button'
import React from 'react'
import { useMainContext } from 'Utils'

import './QuizCta.sass'

import Bang from 'Images/bang'
import LightBulb from 'Images/lightBulb'

function QuizCta({
    hide,
}) {
    const { options } = useMainContext()

    if (hide) return null

    if (!options) return null

    const {
        title,
        copy,
        buttonTitle,
        image
    } = options.quizCta

    const handleClick = () => {
        return null
    }

    return (
        <div className="QuizCta">
            <div className="container">
                {LightBulb}
                <div className="QuizCta-copy">
                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    <div className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
                    <Button label={buttonTitle} onCLick={handleClick}/>
                </div>
                <div className="QuizCta-imageCont">
                    {image && 
                        <img src={image.mediaItemUrl} alt="" />
                    }
                </div>
                {Bang}
            </div>
        </div>
    )
}

export default QuizCta
