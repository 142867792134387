import React from 'react'
import { useMainContext } from "Utils"

import CategoryTag from 'Components/CategoryTag/CategoryTag'
import Button from 'Components/Button/Button'
import Tag from 'Components/Tag/Tag'

import icons from 'Utils/icons'

function Benefit({
    close,
    data
}) {

    const {
        selectedPillar
    } = useMainContext()

    const {
        title,
        category,
        icon,
        program,
        content,
        button,
        date,
        modified
    } = data

    return (
        <div className={`benefitInner ${program ? 'programInner' : selectedPillar.slug}`}>
            <div className="closeModal" onClick={close}>
                <i className="fe fe-x" />
            </div>
            <div className={`iconCont ${program ? 'program' : selectedPillar.slug}`}>
                {icon && icons[icon]}
            </div>
            <div className="benefitContent">
                {category && <CategoryTag category={category}/>}
                <div className="titleCont">
                    <h4 dangerouslySetInnerHTML={{ __html: title }} />
                    <Tag created={date} modified={modified} />
                </div>
                {content &&
                    <div 
                        dangerouslySetInnerHTML={{ __html: content }}
                        className="benefitCopy copy small"
                    />
                }
                {button &&
                    <Button label={button.title} href={button.url}/>
                }
            </div>  
        </div>
    )
}

export default Benefit
