import { GET_BENEFIT_BY_BENEFITTYPE } from 'Utils/queries'
import { useQuery } from 'urql'
import { useMainContext } from 'Utils'
import Tag from 'Components/Tag/Tag'

import icons from 'Utils/icons'

import './BenefitSlider.sass'

const BenefitSliderType = ({
    benefitType,
}) => {
    const {
        openModal,
    } = useMainContext()

    const {
        name,
        slug,
        description,
        typeFields,
    } = benefitType

    const formattedType = {
		terms: slug
	}
    const [{
        data,
        fetching
    }] = useQuery({
        query: GET_BENEFIT_BY_BENEFITTYPE,
        variables: formattedType
    })

    const benefits = data?.benefits.edges

    return (
        <div 
            className={`BenefitSlider-type BenefitSlider-type-${slug}`} 
            style={{
                backgroundColor: typeFields.backgroundColour ? typeFields.backgroundColour : '',
                color: typeFields.textColour ? typeFields.textColour : '',
            }}
        >
            <h2 dangerouslySetInnerHTML={{ __html: name }} />
            <div className='copy' dangerouslySetInnerHTML={{ __html: description }} />
            { fetching ?
                <div className="BenefitSlider-loading" dangerouslySetInnerHTML={{ __html: 'Loading...' }} />
            :
                data ?
                    benefits && 
                        <ul>
                            {benefits.map((benefit, index) => {
                                const _benefit = {
                                    id: benefit.node.id,
                                    date: benefit.node.date,
                                    modified: benefit.node.modified,
                                    slug: benefit.node.slug,
                                    title: benefit.node.title,
                                    category: benefit.node.benefitCategories.edges[0]?.node,
                                    button: benefit.node.benefitFields.button,
                                    excerpt: benefit.node.benefitFields.excerpt,
                                    icon: benefit.node.benefitFields.icon,
                                    program: benefit.node.benefitFields.program,
                                    content: benefit.node.content,
                                }

                                return (
                                    <li
                                        key={ index }
                                        onClick={() => openModal({
                                            type: "BENEFIT",
                                            data: _benefit
                                        })}
                                    >
                                        <i className="fe fe-arrow-right" />
                                        <div>
                                            <span
                                                dangerouslySetInnerHTML={{ __html: _benefit.title }}
                                            />
                                            <Tag created={_benefit.date} modified={_benefit.modified} />
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                : null
            }
            {typeFields.icon && icons[typeFields.icon]}
        </div>
    )
}

export default BenefitSliderType
